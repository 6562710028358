h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: 1.4;
}

h2 {
  font-size: 32px;
  &.large {
    font-size: 36px;
  }

  &.ex-large {
    font-size: 42px;
  }
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
  &.small{
    font-size: 22px;
  }
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;

  &.small {
    font-size: 16px;
    letter-spacing: .4px;
  }
}

p {
  font-size: 16px;

  &.ex-small {
    font-size: 12px;
  }

  &.small {
    font-size: 14px;
  }

  &.large {
    font-size: 18px;
  }

  &.ex-large {
    font-size: 20px;
  }
}


@media screen and (max-width: 768px) {
  h2 {
    font-size: 28px;
    &.large {
      font-size: 22px;
    }
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 20px;
    &.small{
      font-size: 19px;
    }
  }
  h5 {
    font-size: 18px;
  }
  h6{
    font-size: 16px;
    &.small{
      font-size: 14px;
    }
  }
  p{
    font-size: 15px;
    &.large {
      font-size: 16px;
    }
    &.ex-large {
      font-size: 17px;
    }
  }
}

@media screen and (max-width: 576px) {
  h2 {
   font-size: 22px;
  }
  h4{
    font-size: 18px;
    &.small{
      font-size: 17px;
    }
  }
  h5 {
    font-size: 15px;
  }
  p {
    font-size: 14px;
    &.ex-small{
      font-size: 10px;
    }
    &.small {
      font-size: 12px; 
    }
    &.large {
      font-size: 14px;
    }
    &.ex-large {
      font-size: 15px;
    }
  }
}