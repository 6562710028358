:root {
  --white: #ffffff;
  --black: #000000;
  
  --primary: #036aff;
  --primaryDark: #1a5be1;
  --primaryLight: #6b9cff;
  
  --warn: #FE9820;
  --danger: #F93232;
  --success: #28c879;
  
  --subText: #646464;
  --subTextLight: #CECFCF;
  --grey: #a1a1a6;
  --gray: #f5f5f7;
  
  --accountsBg: #131313;
  --accountsCardBg: #1d1d1d;

  --lightBlack: #282828;
  --partnerLabelColor: #565656;
}
