* {
  box-sizing: border-box;
}

html{
  -webkit-tap-highlight-color: transparent !important;
}

ul,
ol,
dl {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.visuallyHidden {
  position: fixed;
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

body {
  font-family: 'Be Vietnam Pro', sans-serif;
  padding: 0;
  margin: 0;
  overflow: hidden overlay;

  @supports (-webkit-appearance:none) {
    overflow: overlay;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
  border-color: transparent;
}

.vietnam {
  font-family: 'Be Vietnam Pro', 'san-serif' !important;
}

.thin {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: 4px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-wrap {
  flex-wrap: wrap;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize !important;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.hidden {
  display: none !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.al-start {
  align-items: flex-start;
}

.al-center {
  align-items: center;
}

.al-end {
  align-items: flex-end;
}

.js-start {
  justify-content: flex-start;
}

.js-center {
  justify-content: center;
}

.js-between {
  justify-content: space-between;
}

.js-end {
  justify-content: flex-end;
}

.primary {
  color: var(--primary) !important;
}

.subText {
  color: var(--subText) !important;
}

.subTextLight {
  color: var(--subTextLight) !important;
}

.partnerLabelColor {
  color: var(--partnerLabelColor) !important;
}

.black {
  color: var(--black) !important;
}

.white {
  color: var(--white) !important;
}

.warn {
  color: var(--warn);
}

.success {
  color: var(--success);
}

.danger {
  color: var(--danger);
}

.grey {
  color: var(--grey);
}
.color-accountsBg {
  color: var(--accountsBg);
}

.bg-gray {
  background-color: var(--gray);
}

.black-bg-menu {
  .MuiMenu-list {
    background: var(--black);

    .MuiMenuItem-root {
      color: var(--white);
    }
  }
}

.validation-field {
  position: relative;
  border: 1.5px solid var(--warn) !important;
}

.validation-text {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 2px;
  color: var(--warn);
}

.MuiFormHelperText-root {
  margin: 10px 0 0 !important;
}

.content-container {
  width: 1170px;
  margin: auto;
  max-width: calc(100% - 120px);

  @media screen and (max-width: 768px) {
    max-width: calc(100% - 60px);
  }

  @media screen and (max-width: 576px) {
    max-width: calc(100% - 32px);
  }
}