@import '../../scss/mixins';

.faq-page-wrapper {
  header {
    height: 0;

    .header-content {
      box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.2);
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);

      .logo-img {
        filter: invert(1);
      }

      .links-list {
        a {
          color: var(--white);

          &.active {
            color: var(--primary);
          }
        }
      }
    }
  }

  .faq-banner {
    position: relative;
    background-color: var(--black);

    .banner-image {
      width: 100%;
      height: auto;

      &.for-mob {
        display: none;
      }

      @media screen and (max-width: 1100px) {
        padding-top: 80px;
      }

      @media screen and (max-width: 768px) {
        padding-top: 30px;

        &.for-mob {
          display: block;
        }

        &.for-desktop {
          display: none;
        }
      }
    }
  }

  .banner-content {
    position: absolute;
    top: calc(50% + 50px);
    left: calc((100% - 1170px) / 2);
    transform: translateY(-50%);
    z-index: 9;

    p {
      width: 555px;
    }

    @media screen and (max-width: 1290px) {
      left: 60px;
    }

    @media screen and (max-width: 1200px) {
      h2 {
        font-size: 28px;
      }

      h3 {
        font-size: 24px;
      }

      p {
        width: 500px;
      }
    }

    @include tab-sm {
      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 20px;
      }

      p {
        width: 400px;
      }
    }

    @include tab-xs {
      left: 30px;
      top: calc(50% - 80px);
      top: auto;
      bottom: 32px;
      transform: none;
    }

    @include mobile {
      left: 16px;
      max-width: calc(100% - 32px);

      p {
        width: auto;
      }

      h2 {
        font-size: 20px;
      }

      h3 {
        font-size: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      h2 {
        font-size: 16px;
      }

      h3 {
        font-size: 14px;
      }

      p {
        font-size: 12px;
      }
    }

    .ask-cover {
      position: relative;
      margin-top: 80px;
      width: 370px;
      max-width: 100%;
      z-index: 9999;

      @media screen and (max-width: 1200px) {
        margin-top: 40px;
        width: 350px;
      }

      @media screen and (max-width: 992px) {
        margin-top: 20px;
        width: 350px;
      }

      input {
        border-radius: 2px;
        font-size: 16px;
        width: 100%;
        font-family: 'Be Vietnam Pro';
        background-color: white;
        padding: 16px 50px 16px 16px;
        border: none;
        outline: none;
        @include tab-xs {
          &::placeholder {
            font-size: 15px;
          }
          font-size: 15px;
        }      
        
        @include mobile {
          &::placeholder {
            font-size: 14px;
          }
          font-size: 14px;
        }
      }

      img {
        position: absolute;
        cursor: pointer;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

      .faq-search-list {
        position: absolute;
        width: 100%;
        top: 50px;
        border: 1px solid #c8c8c8;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
        background-color: white;
        overflow-y: auto;
        max-height: 260px;
        display: none;
        transition: .3s;

        &.show {
          display: block;
        }

        li {
          padding: 12px 20px;
          transition: .3s;
          cursor: pointer;

          &:last-child{
            padding-top: 15px;
            padding-bottom: 15px;
            position: sticky;
            bottom: 0;
            background: white;
          }
          &:not(:first-child) {
            border-top: 1px solid #dbdbdb;
          }
          &:not(:last-child) {
            &:hover {
              background: #f5f5f5;
            }
          }

          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-width: 100%;
          }

          a {
            font-size: 16px;
            @media screen and (max-width: 768px){
              font-size: 14px;
            }
          }
        }
      }
    }

  }

  .faq-content {
    padding: 60px 0 120px;

    @include mobile {
      padding: 20px 0 46px;
    }

    .topics-list {
      border-right: 1px solid #cccccc;

      @media screen and (max-width: 599px) {
        border-right: none;
      }

      .topic {
        padding: 0 24px;

        @media screen and (max-width: 992px) {
          padding: 0 12px;
        }

        h6 {
          padding: 24px 0;
          cursor: pointer;
          outline: none;
          @media screen and (max-width: 992px) {
            padding: 20px 0;
          }
        }

        &:not(:first-child) {
          h6 {
            border-top: 1px solid #bbbbbb;
          }
        }

        .subtopics-list {
          display: none;
          border-top: 1px solid #bbb;
          padding-bottom: 16px;
          padding-top: 8px;

          @media screen and (max-width: 992px) {
            padding-bottom: 12px;
          }

          .subtopic {
            position: relative;
            padding: 8px 30px 8px 16px;
            color: var(--subText);
            cursor: pointer;
            font-size: 16px;

            @media screen and (max-width: 992px) {
              padding: 8px 20px 8px 10px;
            }
            @media screen and (max-width: 768px) {
              font-size: 14px;
            }

            .MuiSvgIcon-root {
              position: absolute;
              top: 50%;
              right: -5px;
              transform: translateY(-50%);

              @media screen and (max-width: 992px) {
                right: 0;
              }
            }

            &.active {
              color: var(--primary);

              .MuiSvgIcon-root {
                color: var(--primary) !important;
              }
            }
          }
        }

        &.active {
          background-color: #f2f2f2;

          h6 {
            border-top: none;
            padding-bottom: 16px;
          }

          &+li {
            h6 {
              border-top: none;
            }
          }

          .subtopics-list {
            display: block;
          }
        }

      }
    }

    .faq-list {

      .MuiAccordion-root {
        &::before {
          opacity: 1;
        }
        &.Mui-expanded{
          margin: 0;
        }

        .MuiAccordionSummary-root {
          padding: 0px;

          .MuiAccordionSummary-content {
            margin: 20px 0;

            @media screen and (max-width: 992px) {
              margin: 16px 0;
            }
          }

          h6 {
            color: #1D1B1C !important;
          }
          h4 {
            color: #1D1B1C !important;
          }

          .MuiAccordionSummary-expandIconWrapper {
            margin-right: -5px;
          }
        }

        .MuiAccordionDetails-root {
          padding: 0px 0px 24px;

          @media screen and (max-width: 992px) {
            padding-bottom: 20px;
          }
        }
      }
    }

    .breadcrumb-cover {
      display: none;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0;
      li {
        font-size: 10px;
        text-transform: uppercase;
        margin-bottom: 10px;
        &:last-child {
          cursor: text;            
        }
        &:not(:last-child) {
          margin-right: 20px;
          position: relative;

          &::after {
            content: '';
            background-image: url('./../../../public/assets/icons/right-chevron-icon-gray.svg');
            position: absolute;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            color: var(--subText);
            right: -13px;
            width: 6px;
            height: 11px;
            top: 45%;
            transform: translateY(-50%);
          }
          a {
            color: var(--subText) !important;
            font-size: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 599px) {
      .MuiGrid-grid-sm-7 {
        display: none;
      }
      .for-desktop{
        display: none;
      }
      .breadcrumb-cover {
        display: flex;
      }
      &.show-detail {
        .MuiGrid-grid-sm-5 {
          display: none;
        }

        .MuiGrid-grid-sm-7 {
          display: block;
        }
      }
    }
  }

.answerText {
  ul,ol,li {
    list-style-type:revert;
  }
  ul,ol {
   padding-left: 25px;
  }
}
.back-cover{
  &.for-mobile{
    display: none;
    @media screen and (max-width: 599px) {
      display: inline-block;
    }
  }
}
}