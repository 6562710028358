.p-0 {
  padding: 0;
}

$spaces: (0, 5, 10, 15, 20);
$sides: (top, bottom, left, right);

@each $space in $spaces {
  @each $side in $sides {
    .p#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px;
    }
  }
}

@media screen and (max-width: 992px) {
  @each $space in $spaces {
    @each $side in $sides {
      .p#{str-slice($side, 0, 1)}-md-#{$space} {
        margin-#{$side}: #{$space}px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @each $space in $spaces {
    @each $side in $sides {
      .p#{str-slice($side, 0, 1)}-sm-#{$space} {
        margin-#{$side}: #{$space}px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  @each $space in $spaces {
    @each $side in $sides {
      .p#{str-slice($side, 0, 1)}-xs-#{$space} {
        margin-#{$side}: #{$space}px;
      }
    }
  }
}
