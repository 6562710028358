.dialog-with-header {
    .MuiPaper-root {
        padding: 0;

        .dialog-heading {
            padding: 32px 32px 24px;
            border-bottom: 1px solid #3d3d3d;
            position: relative;
            flex-wrap: wrap;
            z-index: 9;

            @media screen and (max-width: 768px) {
                padding: 24px 24px 18px;
            }

            @media screen and (max-width: 576px) {
                padding: 24px 16px;
            }

            h5 {
                letter-spacing: .2px;
            }

            &.has-icon {
                padding-right: 60px;

                .close-btn-icon {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }


        }

        .dialog-body {
            padding: 28px 32px 32px;

            @media screen and (max-width: 768px) {
                padding: 24px;
            }

            @media screen and (max-width: 576px) {
                padding: 24px 16px;
            }
        }

    }
}

.confirmation-dialog {
    .MuiPaper-root {
        padding: 44px;

        @media screen and (max-width: 768px) {
            padding: 24px;
        }

        @media screen and (max-width: 576px) {
            padding: 24px 16px;
        }
    }

    .otp-field {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -5px;

        .otp-field__input {
            padding: 13.5px 14px;
            width: 44px;
            height: 44px;
            margin: 0 5px;
            box-sizing: border-box;
            border: 1px solid #88939e;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            background: transparent;
            color: white;
        }


        .otp-field__input__register {
            padding: 13.5px 14px;
            width: 44px;
            height: 44px;
            margin: 0 5px;
            box-sizing: border-box;
            border: 1px solid #88939e;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            background: transparent;
            color: black;
        }


        .wrong_otp {
            border: 1px solid #f84444;

        }
    }

    .info-icon {
        width: 80px;
        height: 80px;
        margin-bottom: 32px;

        @media screen and (max-width: 768px) {
            width: 60px;
            height: 60px;
            margin-bottom: 30px;
        }

        @media screen and (max-width: 576px) {
            width: 50px;
            height: 50px;
            margin-bottom: 20px;
        }
    }
}

.cancellation-dialog {
    .MuiDialog-container {
        @media screen and (max-width: 899px) {
            align-items: flex-end;
        }
    }

    .MuiPaper-root {
        z-index: 9999 !important;
        background: #1D1D1D;
        color: #ffffff;
        padding: 0px !important;
        border-radius: 4px;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 899px) {
            overflow: auto;
            margin: 84px 0 0 0;
            width: 100%;
            height: calc(100% - 84px);
            max-height: calc(100% - 84px);

        }

        .close-dialog-icon {
            position: absolute;
            top: 9px;
            right: 9px;

            @media screen and (max-width: 576px) {
                top: 5px;
                right: 5px;
                padding: 4px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .cancellation-dialog-content {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 500px;
            max-height: calc(100vh - 64px);

            @media screen and (max-width: 899px) {
                height: 100%;
                max-height: 100%;
            }

            .img-cover {
                width: 35%;

                @media screen and (max-width: 899px) {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: table-cell;
                }
            }

            .cancellation-flow-content {
                width: 65%;
                padding: 40px 30px 0;
                height: 500px;
                max-height: calc(100vh - 64px);
                overflow: auto;

                @media screen and (max-width: 899px) {
                    width: 100%;
                    height: auto;
                    max-height: auto;
                }

                @media screen and (max-width: 576px) {
                    padding: 30px 16px 0;
                }

                .just-before-you-decide-content,
                .what-changed-your-mind-content,
                .confirm-booking-cancellation-content,
                .cancellation-request-received-content {
                    display: flex;
                    align-items: stretch;
                    justify-content: space-between;
                    flex-direction: column;
                    height: 100%;
                }

                .bottom-part-dialog {
                    padding-bottom: 30px;

                    @media screen and (max-width: 576px) {
                        padding-bottom: 20px;
                    }
                }
            }
        }


        .cancel-reason {
            padding: 12px 16px;
            border: 1px solid #343434;
            background-color: #1C1C1C;
            color: var(--white);
            cursor: pointer;
            font-size: 14px;

            @media screen and (max-width: 768px) {
                font-size: 12px;
                padding: 10px 20px;
            }

            @media screen and (max-width: 576px) {
                font-size: 10px;
                padding: 8px 16px;
            }

            &.active {
                background-color: #1d2d25;
                border-color: #26a968;
            }
        }

        .cancel-reason-field {
            .MuiInputBase-root{
                padding-bottom: 30px;
                background: #2D2D2D;
                color: var(--white);
                .MuiInputBase-input{
                    background: #2D2D2D;
                }
                &.Mui-focused{
                    fieldset{
                        border-color: #2D2D2D !important;
                    }
                }
            }
            .w-100{
                &.active {
                    fieldset {
                        border-color: #26a968;
                    }
                }
            }
            .hint-text{
                position: absolute;
                bottom: 10px;
                right: 10px;
                font-size: 10px;
            }
            fieldset{
                border-color: #2D2D2D;
            }
        }

        .info-icon {
            width: 80px;
            height: 80px;
            margin-bottom: 32px;

            @media screen and (max-width: 768px) {
                width: 60px;
                height: 60px;
                margin-bottom: 30px;
            }

            @media screen and (max-width: 576px) {
                width: 50px;
                height: 50px;
                margin-bottom: 20px;
            }
        }
    }
}

.summary-dialog {
    .features-list {
        padding-left: 20px;

        li {
            list-style-type: decimal;
            font-size: 16px;
            color: var(--subText);

            @media screen and (max-width: 576px) {
                font-size: 14px;
            }

            &:not(:last-child) {
                padding-bottom: 14px;
            }

            span {
                &:first-child {
                    width: 50%;
                    display: inline-block;
                    padding-right: 20px;

                    @media screen and (max-width: 576px) {
                        width: 100%;
                        padding-bottom: 5px;
                        padding-right: 0;
                    }
                }

                &:last-child {
                    font-size: 14px;
                    font-weight: bold;

                    @media screen and (max-width: 576px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &.black-theme-dialog {
        li {
            color: var(--subTextLight);
        }
    }
}