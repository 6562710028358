@import '../../scss/mixins';

p{
    font-size: inherit;
}
blockquote {
    padding: 1rem;
    border-left: 0.35rem solid var(--primary);
}
// added the style for responsive block
@include tab-xs {
    li {
        font-size: 15px;
    }
@include mobile {
    li {
        font-size: 14px;
    } 
}
}
