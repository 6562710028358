.m-0 {
  margin: 0 !important;
}

$spaces: (0, 4, 5, 6, 8, 10, 12, 15, 16, 18, 20, 24, 25, 28, 30, 32, 36, 40);
$sides: (top, bottom, left, right);

@each $space in $spaces {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  }
}

@media screen and (max-width: 992px) {
  @each $space in $spaces {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-md-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @each $space in $spaces {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-sm-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  @each $space in $spaces {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-xs-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }
    }
  }
}
