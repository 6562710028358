@import './../../scss/mixins';

.account-page {
  background: var(--accountsBg);

  .verified {
    color: white;
  }

  .header-content {
    box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.2);
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    .header-content-inner {
      &>a {
        filter: invert(1);
      }

      a {
        color: white;
      }

      .MuiButtonBase-root {
        filter: invert(1);
      }
    }
  }

  .explore-section {
    h3 {
      color: var(--white);
    }
  }

  footer {
    border-top: 1px solid #4d4d4d;
  }

  @include tab-xs {
    h5 {
      font-size: 14px;
    }
  }
}

.account-content {
  padding-top: 85px;
  padding-bottom: 110px;
  color: var(--white);

  @include desktop-lg {
    padding-top: 60px;
  }

  @include tab-xs {
    padding-top: 65px;
    padding-bottom: 80px;
  }

  @include mobile {
    padding-top: 55px;
    padding-bottom: 60px;
  }

  .order-status {
    padding: 8px 12px;
    display: inline-block;
    border: 2px solid #2f2f2f;
    background: #242424;
    border-radius: 2px;
    letter-spacing: 2px;

    @include tab-xs {
      padding: 6px 8px;
      border-width: 1px;
      letter-spacing: 1px;
      font-size: 10px;
    }

    &.success {
      color: #3dcd85;
    }

    &.failure {
      color: #f93232;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
    }
    &.inprogress {
      color: #E97204;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .breadcrumb-cover {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;

    @include tab-xs {
      margin-bottom: 10px;
    }

    li {
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 20px;

      @include tab-xs {
        margin-bottom: 10px;
        font-size: 10px;
      }

      @include mobile {
        margin-bottom: 8px;
        font-size: 8px;
      }

      &:not(:last-child) {
        margin-right: 40px;
        position: relative;

        @include tab-xs {
          margin-right: 30px;
        }

        @include mobile {
          margin-right: 20px;
        }

        &::after {
          content: '';
          background-image: url('./../../../public/assets/icons/right-chevron-icon.svg');
          position: absolute;
          background-position: center;
          background-repeat: no-repeat;
          right: -25px;
          height: 11px;
          width: 8px;
          color: var(--subText);
          top: 2px;

          @include tab-xs {
            right: -18px;
            height: 8px;
            width: 8px;
          }

          @include mobile {
            right: -13px;
            height: 6px;
            width: 8px;
            top: 2px;
          }
        }

        a {
          color: var(--subText) !important;
        }
      }
    }
  }

  .card {
    padding: 40px;
    background-color: var(--accountsCardBg);
    border-radius: 4px;
    width: 100%;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.24);

    @include tab-xs {
      padding: 24px;
    }

    @include mobile {
      padding: 16px;
    }
  }

  .breif-info-card {
    align-items: stretch;
    padding: 32px 40px;

    @include tab-xs {
      padding: 24px;
    }

    @include mobile {
      padding: 16px;
    }

    .info {
      width: calc(100% - 80px);
      padding-left: 24px;
      word-break: break-all;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-direction: column;
      @include tab-xs {
        width: calc(100% - 60px);
      }
      @include mobile {
        width: calc(100% - 50px);
        padding-left: 12px;
      }

      h5 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height: 1.2;
  
        @include mobile {
          font-size: 11px;
        }
      }
    }

    .profile-img-cover {
      width: 80px;
      height: 80px;
      border-radius: 4;
      overflow: hidden;

      @include tab-xs {
        width: 60px;
        height: 60px;
      }

      @include mobile {
        width: 50px;
        height: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .coming-soon-card {
    padding: 0;
    min-height: 145px;
    position: relative;
    box-shadow: 0px 0px 30px 1px rgba(255, 255, 255, 0.05);

    @include tab-xs {
      min-height: 110px;
    }

    @include mobile {
      min-height: 90px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url('./../../../public/assets/images/accounts/coming-soon.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: center;
    }
  }

  .my-visit-card {    
    position: relative;
    z-index: 9;
    overflow: hidden;
    padding: 0;
    height: fit-content;
    border-radius: 4px;
    background-color: var(--black);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.24);
    

    @include tab-xs {
      height: auto;
      align-items: normal;
    }

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      left: 36%;
      top: 0;
      width: 13%;
      //background-image: linear-gradient(to right, black, transparent);
      background-image: linear-gradient(84deg, rgba(9, 8, 9, 0.00) 5.54%, #090809 96.37%);
      z-index: 1;
      

      @include tab {
        left: 0;
        bottom: 0;
        top: 0;
        width: 0%;
        height: 0%;
        background-image: linear-gradient(to top, black, transparent);
      }
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url('https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/My_Account_Test_Ride_Desktop.png');
      background-size: cover;
      background-repeat: no-repeat;      
      background-position: left;
      z-index: -1;

      @include mobile {
        background-image: url('https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/My_Account_Visit_Mobile.png');
        background-position: top;
        background-size: contain;
      }
    }
    
    .empty-test-ride {
      box-sizing: border-box;
      position: absolute;
      width: 201px;
      height: 157px;
      left: 929px;
      top: 202px;
      background: #131313;
      border: 1px dashed #2F2F2F;
      border-radius: 6px;
    }
    
    .book-test-ride {
      position: absolute;
      width: 102px;
      height: 18px;
      left: 50px;
      top: 70px;
      font-family: 'Be Vietnam Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #036AFF;
    }
    
    .group {
      position: absolute;
      width: 6px;
      height: 6px;
      left: 162px;
      top: 83px;
      transform: rotate(180deg);
    }
    
    .vector-test-ride {
      position: absolute;
      width: 6px;
      height: 2.67px;
      background: #036AFF;
    }
    
    .vector-test-ride.first {
      left: 156px;
      top: 80.34px;
    }
    
    .vector-test-ride.second {
      left: 156px;
      top: 77px;
    }
    
    .bookinglimit {
      position: relative;
      font-size: 14px;
      margin-top:12px;
      margin-bottom: 28px;
      display: flex;
      flex-direction: row;
      gap: 4px;

      @include mobile {
        font-size: 12px;
      }
    }

    .my-visit-space {
      margin-top: 32px;
      margin-left: 60px;
      position: relative;
      z-index: 9;
      left: 37%;


      @include tab {        
        margin-top: 282px;
        margin-left: 16px;
        left: 0;

        h4 {
          font-size: 14px !important;
          font-family: "Monument Extended";
        }
      }

      .grab-chance {
        @include tab-xs {
          font-size: 12px;
          width: auto !important;
        } 
      }

      .view-my-visit {
        @include tab-xs {
          font-size: 14px !important;          
        }
      }

      .view-booking-details {
        padding: 12px;
        border-radius: 6px;
        border: 1px solid #2F2F2F;
        background: #131313;
        //height: 157px;
        width: 212px;
        margin-bottom: 20px;

        @include tab-xs {
         width: 100%;
         margin-bottom: 12px;
        }

        .dealer-name {
          @include tab-xs {
            font-size: 14px !important;
            font-weight: 600 !important;
           } 
        }

        .view-booking-details-btn{
          padding: 9px;
          //width: 169px;
          width: 100%;
          height: 32px;
          font-size: 10px;
          font-weight: 600;

          @include mobile {
            width: 100%;
            font-size: 12px;
            font-weight: 500;
            padding: 8px;
          }
        }
      }
      
      .view-book-ride {
        padding: 16px;        
        border-radius: 6px;
        border: 1px dashed #2F2F2F;
        background: #131313;
        height: 100%;
        width: 201px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        // @include tab-xs {
        //  width: 100%;
        //  margin-bottom: 12px;
        // }

        @include mobile {
          width: 100%;
          height: 142px;
        }
        
      }
    }
    
  }

  .orders-card {
    .no-orders-yet {
      padding: 130px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        padding: 100px 0;
      }

      p {
        width: 625px;
        max-width: 100%;
      }
    }

    .single-order {
      border: 2px solid #3a3a3a;
      border-radius: 4px;
      background-color: var(--accountsBg);
      padding: 24px;

      @include tab-xs {
        padding: 15px;
      }

      .bike {
        max-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-bottom: 20px;

        @include tab-xs {
          padding: 15px;
        }

        @include mobile {
          padding: 0;
        }

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      @include mobile-xs {
        .flex {
          flex-wrap: wrap;

          button {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
    }
  }

  .create-aera-card {
    height: 530px;
    position: relative;
    z-index: 9;
    overflow: hidden;
    background-color: var(--black);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    // @include tab-xs {
    //   justify-content: flex-end;
    // }

    @media  screen and (max-width: 576px) {
      justify-content: flex-end;
    }

    .create-aera-text {
      position: relative;
      z-index: 9;
      width: 530px;
      max-width: 100%;
      @include mobile {
        h4{
          font-size: 14px;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      left: 20%;
      top: 0;
      width: 50%;
      background-image: linear-gradient(to right, black, transparent);
      z-index: 1;

      @include tab-xs {
        left: 0;
        bottom: 0;
        top: auto;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to top, black, transparent);
      }
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 80%;
      background-image: url('https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Create_your_Aera_Desktop.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
      right: 0;
      top: 0;
      z-index: -1;

      @include tab-xs {
        width: 100%;
        height: 80%;
        // right: auto;
        // top: auto;
        background-size: cover;
        background-position: center;
      }

      @include mobile {
        background-image: url('https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Create_your_Aera_Mobile.png');
      }
    }

    h4 {
      width: 475px;
      max-width: 100%;
    }

    .choose-aera {
      width: 350px;
      max-width: 100%;
      z-index: 99;
    }
  }

  .matter-shop-section {
    padding: 55px;
    position: relative;
    background-color: var(--black);
    overflow: hidden;

    @include tab-xs {
      padding: 30px 24px;
      height: 500px;
    }

    @include mobile {
      padding: 30px 16px;
      height: 450px;
    }

    h2 {
      width: 480px;
      position: relative;
      z-index: 9;
      max-width: 100%;

      @include tab-xs {
        font-size: 24px;
      }

      @include mobile {
        font-size: 18px;
      }
    }

    p {
      position: relative;
      z-index: 9;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      background-image: url('./../../../public/assets/images/accounts/matter-shop-bg.svg');
      height: 100%;
      width: 60%;
      background-repeat: no-repeat;
      background-position: top right;
      background-size: cover;

      @include tab-xs {
        width: 100%;
        height: 95%;
        background-position: bottom center;
        top: auto;
        right: auto;
        left: 0;
        bottom: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      right: 40%;
      top: 0;
      width: 20%;
      height: 100%;
      background-image: linear-gradient(to right, var(--black), transparent);
      z-index: 2;

      @include tab-xs {
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 80%;
        background-image: linear-gradient(to bottom, var(--black), transparent);
      }
    }
  }

  .my-favourite-card {
    .heading-area {
      @include mobile {
        flex-wrap: wrap;
      }
    }

    .custom-tab {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include mobile {
        width: 100%;
        justify-content: flex-start;
      }

      .single-tab {
        padding: 16px 26px;
        font-size: 14px;
        background: transparent;
        cursor: pointer;
        transition: 0.3s;

        @include tab-xs {
          padding: 12px 20px;
          font-size: 12px;
        }

        @include mobile {
          padding: 10px 16px;
          font-size: 10px;
        }

        &:hover {
          background-color: #2b2b2b;
        }

        &.active {
          background-color: #303030;
          box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.5);
        }
      }
    }

    .saved-aera-card {
      .bike-cover {
        position: relative;
        padding: 10px;
        margin-bottom: 15px;
        height: 230px;
        border: 2px solid #3a3a3a;
        border-radius: 4px;
        background-color: var(--accountsBg);
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          height: 190px;
        }

        .menu-icon {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 40px;
          height: 40px;
        }

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

    .add-aera-to-save-cover {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #454545;
      background: var(--black);
      border: 2px dashed #454545;
      height: 230px;
      cursor: pointer;

      @include tab-xs {
        height: 180px;
      }

      @include mobile {
        height: 150px;
      }

      .MuiSvgIcon-root {
        font-size: 60px;

        @include mobile {
          font-size: 48px;
        }
      }
    }
  }

  .my-profile-card {
    margin-bottom: 50px;
    flex-wrap: wrap;

    .profile-cover {
      width: 240px;
      height: auto;
      aspect-ratio: 1/1;
      border-radius: 6px;
      overflow: hidden;
      position: relative;

      @include tab {
        width: 200px;
      }

      @include tab-xs {
        width: 160px;
      }

      .uploaded-image {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .upload-new-cover {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        transition: 0.3s;

        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }

      &:hover {
        .upload-new-cover {
          opacity: 1;
        }
      }

      &.no-hover {
        .upload-new-cover {
          opacity: 0;
        }
      }
    }

    form {
      flex: 1;
      padding-left: 50px;

      .btn {
        width: 250px;
      }

      @include tab-xs {
        padding-left: 30px;
      }

      @include mobile {
        width: 100%;
        flex: auto;
        padding: 20px 0 0;

        .MuiSvgIcon-root {
          font-size: 14px;
        }

        .btn {
          width: 100%;
        }
      }
    }
  }

  .order-detail-card {
    padding: 32px;
    @include tab-xs {
      padding: 24px;
    }

    @include mobile {
      padding: 16px;
    }
    h4 {
      @include mobile {
        font-size: 16px;
      }
    }

    .product-img-cover {
      background: var(--accountsBg);
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      aspect-ratio: 1/1.135;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }

      @include mobile {
        aspect-ratio: 0;
      }
    }

    .btn-select-aera {
      padding: 0px;
      border: 1px solid #505050;
      background: #262626;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--white);
      cursor: pointer;
      transition: 0.3s;

      &.active {
        border-color: var(--primary);
      }

      @include mobile {
        font-size: 10px;

        .aera-model-img {
          width: 70px;
        }
      }
    }

    .bike-color {
      padding-left: 44px;
      position: relative;
      margin-top: -4px;

      @include mobile {
        padding-left: 20px;
        margin-top: -3px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 22px;
        top: 8px;
        width: 4px;
        height: 4px;
        background: var(--subText);
        border-radius: 50%;

        @include mobile {
          left: 10px;
        }
      }
    }

    .what-included-cover {
      background: var(--accountsBg);
      border-radius: 4px;
      padding: 24px;

      @include tab-xs {
        padding: 20px;
      }

      @include mobile {
        padding: 16px;
      }

      .single-inclusion {
        border-radius: 4px;
        padding: 12px 15px;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border: 1px solid #1e1e1e;
        background: #191919;
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
      }
    }

    .price-for-you-cover {      
      border-radius: 2px;
      border: 1px solid var(--Text-282828, #282828);
      background: #282828;
      box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.28);
      padding: 12px;
      //display: flex;
      //align-items: center;
      //justify-content: space-between;
    }
  }

  .order-detail-page {
    .color-tiles {
      display: flex;
      align-items: center;
      column-gap: 12px;

      button {
        background-color: transparent;
        border: none;

        i {
          margin-bottom: 3px;
        }
      }

      .color-tile {
        width: 31px;
        height: 30px;
        padding: 2.5px;
        cursor: pointer;

        &.active {
          background: url(../../../public/assets/images/selected-color.svg) no-repeat center;
          background-size: contain;
        }
      }
    }
  }
}

.btn-clipped-cover {
  display: inline-block;
  filter: drop-shadow(0px 0px 4px rgba(31, 122, 254, 0.8));

  .btn-clipped {
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 40px 4px 4px;
    cursor: pointer;
    clip-path: polygon(10px 0,
        100% 0,
        100% calc(100% - 10px),
        100% calc(100% - 10px),
        calc(100% - 10px) 100%,
        calc(100% - 10px) 100%,
        0 100%,
        0 10px);
    z-index: 1;
    border-radius: 0 3px 0 3px;
    border: none;
    @include mobile {
      padding: 4px 20px 4px 4px;
      font-size: 12px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-image: linear-gradient(to bottom right, #1f7afe, #adcefe);
      padding: 1px;
      border-radius: 0 3px 0 3px;
      z-index: -2;
      transition: 0.4s;
      clip-path: polygon(10px 0,
          100% 0,
          100% calc(100% - 10px),
          100% calc(100% - 10px),
          calc(100% - 10px) 100%,
          calc(100% - 10px) 100%,
          0 100%,
          0 10px);
    }

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      border-radius: 0 3px 0 3px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: var(--white);
      z-index: -1;
      clip-path: polygon(10px 0,
          100% 0,
          100% calc(100% - 10px),
          100% calc(100% - 10px),
          calc(100% - 10px) 100%,
          calc(100% - 10px) 100%,
          0 100%,
          0 10px);
    }

    &.black-clipped {
      color: var(--white);

      &::after {
        background: var(--black);
      }
    }

    &:hover {
      &::before {
        transform: rotate(180deg);
      }
    }

    .aera-model-img {
      margin-right: 16px;
      clip-path: polygon(8px 0,
          100% 0,
          100% calc(100% - 8px),
          100% calc(100% - 8px),
          100% 100%,
          100% 100%,
          0 100%,
          0 8px);
    }

    .right-chevron-icon {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      @include mobile {
        right: 20px;
      }
    }
  }
}

.edit-profile-menu {
  .MuiMenu-paper {
    transform: translate(-50%, 50px) !important;

    .upload-profile-link-cover {
      position: relative !important;
      cursor: pointer !important;

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 9;
      }
    }
  }
}

body {
  &:has(.edit-profile-menu) {
    .upload-new-cover {
      opacity: 1 !important;
    }
  }
}
.modalrequestassistance {

}
.modalrequestassistance .h-100{
  height: 100%;
}
.modalrequestassistance .heading {

}
.modalrequestassistance .btn-back {
  color: #fff;
  background: #2D2D2D;
  border: 1px solid #2D2D2D;
}
.modalrequestassistance .btnflat {
  box-sizing: border-box;
  background: #1C1C1C;
  border: 1px solid #343434;
  padding: 0.5rem 1rem;
  width: 100%;
  color: #fff;
  text-align: left;
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.modalrequestassistance .btnflat.active {
  border: 1px solid #036AFF;
}
.modalrequestassistance .img-ok {
  max-width: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.modalrequestassistance .txt-request-received {
  margin-top: 2rem;
  color: #CECFCF;
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.modalrequestassistance .txt-request-received.dark {
  color: #8E8E8E;
}
.modalrequestassistance .txt-field-stage3{
  background: #2D2D2D;
  color: #fff !important;
}
.modalrequestassistance .txt-field-stage3  .MuiInputBase-root{
  background: #2D2D2D;
}
.custom-field.black-bg {
  position: relative;
}
.custom-field.black-bg .txt-field-stage3  .MuiInputBase-input{
  background: #2D2D2D;
}
.custom-field.black-bg .txt-field-stage3.small  .MuiInputBase-input{
  padding-right: 70px;
}

.custom-field.black-bg .txt-field-stage3.large  .MuiInputBase-input{
  padding-bottom: 20px;
}

.modalrequestassistance .alertmsg {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  margin-top: 0rem;
}
.modalrequestassistance .error-message {
  color: var(--Error, #F93232);
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  // line-height: 12px; /* 100% */
  width:max-content;
  background-color: #1D1D1D;
}
.modalrequestassistance .h-100 {
  height: 100%;
}
.modalrequestassistance .txt-counter{
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  /* identical to box height */
  text-align: right;
  color: #FFFFFF;
  opacity: 0.3;

  position: absolute;
  right: 10px;
  top: 15px;
}
.modalrequestassistance .txt-counter.txt-large{
  bottom: 0px;
  top: initial;
}
.footer-stage-1 {
  align-items: 'flex-end';
  margin-top: '1rem'
}
.top-button-stage-1 {

}
.top-button-stage-1 .left{
  padding-right: 0.5rem;
}
.top-button-stage-1 .right {
  padding-left: 0.5rem;
}
.footer-stage-1 {
  align-items: flex-end;
}
.footer-stage-1 .left {
  padding: 1rem 0.5rem 1rem 0rem;
}
.footer-stage-1 .right {
  padding: 1rem 0rem 1rem 0.5rem;
}
.stage-2 {
  
}
.stage-3 {
  padding: 1rem;
}
.stage-3 .footer {
  width: 100%; flex-direction: initial;
}
.stage-3 .footer2 {
  align-items: flex-end;
}
.stage-3 .footer .left {
  padding: 1rem 0.5rem 0rem 2px;
}
.stage-3 .footer .right {
  padding: 1rem 0rem 0rem 0.5rem;
}
.stage-4 {
  padding: 1rem;
}
@media (max-width: 899px) {
  .footer-stage-1 .left {
    padding: 1rem 0rem 0rem 0rem;
  }
  .footer-stage-1 .right {
    padding: 1rem 0rem 1rem 0rem;
  }
  .modalrequestassistance .btnflat {
    padding: 1rem;
  }
  .top-button-stage-1 .left,
  .top-button-stage-1 .right {
    padding:0;
  }
  .top-button-stage-1 .left {
    padding-bottom: 1rem;
  }

  .stage-3 .footer .left {
    padding: 1rem 0rem 0rem 0rem;
  }
  .stage-3 .footer .right {
    padding: 1rem 0rem 0rem 0rem;
  }
  .stage-3 .footer{
    flex-direction: column;
  }
}
.confirm-booking {
  @include mobile {
    width: 264px;
  }
}
.confirm-cancellation {
  background-color: #2D2D2D !important;  
}
.danger {
  z-index: 99999 !important;
}