.btn {
  padding: 13.5px 16px;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid var(--primary);
  outline: none;
  color: var(--primary);
  height: 48px;
  line-height: 1.4;
  background: var(--white);
  transition: 0.3s;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Be Vietnam Pro', sans-serif;
  position: relative;
  overflow: hidden;

  &.btn-primary {
    color: var(--white);
    background-color: var(--primary);
  }

  &.btn-gray {
    color: var(--subText);
    border-color: var(--subText);
    background-color: transparent;
  }

  &.btn-black-active {
    color: var(--primary);
    border-color: #2c2d2f;
    background-color: #202020;
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &.btn-black-cancel {
    color: var(--white);
    border-color: #303030;
    background-color: #191919;
  }

  &.btn-white {
    background-color: var(--white);
    border-color: var(--white);
    color: var(--black);
  }

  &.btn-cancel {
    background-color: #F4F4F4;
    border-color: #F4F4F4;
    color: var(--black);
  }

  &.small {
    @media screen and (max-width: 768px) {
      padding: 11px 12px;
      font-size: 12px;
      height: 40px;
    }
  }

  &.lg {
    .icon-right{
      line-height: 0.2;
      padding-left: 12px;
    }
    @media screen and (min-width: 768px) {
      font-size: 20px;
      padding: 12px 24px;
    }
  }

  .icon-right {
    padding-left: 4px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: -1;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);
  }

  &:hover {
    &::before {
      z-index: 1;
      animation: imageH 1s;
      -webkit-animation: imageH 1s;
      -moz-animation: imageH 1s;
      -ms-animation: imageH 1s;
      -o-animation: imageH 1s;
    }
  }

  &:disabled {
    &:hover {
      &::before {
        display: none;
      }
    }
  }
}

.btn-gradiant-border {
  position: relative;
  z-index: 1;
  border-radius: 3px;
  border: none;
  padding: 13.5px 16px;
  font-size: 14px;
  color: var(--primary);
  height: 48px;
  line-height: 1;
  background: var(--white);
  transition: 0.3s;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Be Vietnam Pro', sans-serif;
  box-shadow: 1px 0px 6px 1px rgba(31, 122, 254, 0.8);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 3px);
    height: calc(100% - 4px);
    background-image: linear-gradient(to right, #1f7afe, #84b6ff);
    // background-image: linear-gradient(to right, #1f7afe, #adcefe);
    padding: 2px;
    border-radius: 3px;
    z-index: -2;
    transition: 0.3s;
  }

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 3px;
    width: calc(100% - 3px);
    height: calc(100% - 4px);
    background: var(--white);
    z-index: -1;
  }

  &.with-black-bg {
    color: var(--white);
    background-color: var(--black);

    &::after {
      background: var(--black);
    }
  }

  &.large {
    display: flex;
    align-items: center;
    text-decoration: none;
    @media screen and (min-width: 576px){
      font-size: 16px;
      padding: 22px 24px;
      height: auto;
    }
  }
}

@-webkit-keyframes imageH {
  100% {
    left: 125%;
  }
}

@keyframes imageH {
  100% {
    left: 125%;
  }
}