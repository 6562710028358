@import '../../scss/mixins';

.matter-dialog {
  .MuiDialog-container{
    .MuiPaper-root {
      margin: 0 !important;
      width: 100% !important;
      padding: 28px 20px !important;
    }
  } 

  .MuiDialogContent-root {
    padding: 0px !important;
    .ask-cover {
      // position: relative;
      //margin-bottom: 60px;
      width: 372px;
      //height: 48px;
      max-width: 100%;
      
      // z-index: 9999;

      @media screen and (max-width: 1200px) {
        //margin-top: 40px;
        width: 350px;
      }

      @media screen and (max-width: 992px) {
        //margin-top: 20px;
        width: 100%;
      }

      input {
        padding: 15px 16px;            
        border-radius: 4px;
        border: 1px solid var(--Text-CECFCF, #CECFCF);
        background: #FFF;
        
        font-size: 14px;
        width: 100%;
        font-family: 'Be Vietnam Pro';
        //border: none;
        outline: none;
        @include tab-xs {
          &::placeholder {
            font-size: 15px;
          }
          font-size: 15px;
        }      
        
        @include mobile {
          &::placeholder {
            font-size: 14px;
          }
          font-size: 14px;
        }
      }

      img {
        position: absolute;
        cursor: pointer;
        left: 90%;
        top: -70%;

        @include mobile {
          left: 85%;
          top: 40%;
        }

        @media screen and (min-width: 530px) and (max-width: 550px) {
          left: 90%;
        }
      }

      .faq-search-list {
        position: absolute;
        width: 100%;
        top: 50px;
        border: 1px solid #c8c8c8;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
        background-color: white;
        overflow-y: auto;
        max-height: 260px;
        display: none;
        transition: .3s;

        &.show {
          display: block;
        }

        li {
          padding: 12px 20px;
          transition: .3s;
          cursor: pointer;

          &:last-child{
            padding-top: 15px;
            padding-bottom: 15px;
            position: sticky;
            bottom: 0;
            background: white;
          }
          &:not(:first-child) {
            border-top: 1px solid #dbdbdb;
          }
          &:not(:last-child) {
            &:hover {
              background: #f5f5f5;
            }
          }

          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-width: 100%;
          }

          a {
            font-size: 16px;
            @media screen and (max-width: 768px){
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.filters {
  margin-top: 20px;    

  .sort-by-icon {
    background: none !important;
  }

  .caret-icon {
    margin-left: 180px;
  }
  
  .filter-by, .sort-by {      
    color: var(--text-646464, #646464);
    font-family: Be Vietnam Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    margin-bottom: 12px;    
  }
  
  @media screen and (max-width: 500px) {
    margin-top: 0px;
    .filter-by, .sort-by {
      font-size: 14px;
    }      
    .caret-icon {
      margin-left: 135px;
      margin-bottom: 6px;
    }
  }

  .filter-dropdown {
    display: flex;
    gap: 16px;      
  }

  .adornment {
    z-index: 9;
    display: flex;
    position: absolute;
    right: 0;
    width: 100%;
    height: 22px;
    justify-content: start;      

    p {
      color: #000;
      font-family: Be Vietnam Pro;
    }
  }

  .downCaretBlackIcon {
    margin: 16px;
  }

  .MuiGrid-item {
    padding-top: 0;
  }    

  .MuiInputAdornment-root {
    z-index: 0;
    background: white;
    height: auto;
    padding-left: 5px;
    position: absolute;
    //right: 0;
    cursor: pointer;
    pointer-events: none;
    padding-right: 12px;      
    width: 100%;

    .adornmentDate {
      margin-left: 8px;
      margin-right: 130px;
      color: #000;
      font-family: Be Vietnam Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */

      @media screen and (max-width: 500px) {
        margin-right: 0px;
        font-size: 14px;
      }
    }

    .adornmentReadTime {
      margin-left: 6px;
      margin-right: 90px;
      color: #000;
      font-family: Be Vietnam Pro;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      @media screen and (max-width: 500px) {
        margin-right: 40px;
        font-size: 14px;
      }
    }

    .closeBtn {
      position: absolute;
      z-index: 20;
    }
  }

  .MuiInputBase-root {
    border-radius: 2px;
    border: 1px solid var(--Font-Color-3---Light-theme, #B3B3B3);
    width: 220px;

    @include mobile {
      width: 99.8%;
      margin-left: 1px;
    }
  }

  .MuiSelect-select {
    background: white;
    width: 160px;
    z-index: 9;    

    @media screen and (max-width: 550px) {
      width: 100%;
    }
  }

  .MuiInputBase-input {
    @include mobile {
      padding: 7.5px 14px;
    }    
  }

  .MuiSelect-icon {
    display: none;
  }    
  
}

.MuiMenu-root {
  z-index: 20000 !important;
}

.filter-options {
  margin-top: 60px;
  margin-bottom: 48px;

  @media screen and (max-width: 899px) {
    .sort-by-section{
      margin-top: 12px;
      margin-left: 87px;
    }
  }
  
  @include mobile {
    margin-top: 0px;
    margin-bottom: 48px;        
  }
}

.filter-options-error {
  margin-top: 40px;
  margin-bottom: 28px;

  @media screen and (max-width: 899px) {
    .sort-by-section{
      margin-top: 24px;
      margin-left: 87px;
    }
  }            
}

.matter-stream {
  
  header {  
    .header-content {
      box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.2);
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      right: auto;
          
      .logo-img {
        filter: invert(1);
      }
      
      .links-list {
        a {
          color: var(--white);
    
          &.active {
            color: var(--primary);
          }
        }
      }
    }
  }      

  .matter-stream-header {
    display: flex;
    //margin-top: 38px;
    justify-content: space-between;
    height: 202px;
    position: relative;
    z-index: 10;
    background-color: black;
    border-top: 1px solid black;

    @include mobile {     
      //margin-top: 28px; 
      height: 92px;
      display: block;
      background: url(https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Stream_Mobile_New.webp);
      background-position: center;
      background-size: cover;      
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      //margin-top: 28px; 
      height: 200px;
      display: block;
      background: url(https://sitecdn.matter.in/newsite-uat/matter/assets/images/NEW_IMAGES/Stream_Desktop_New.webp);
      background-position: center;
      background-size: cover;      

      margin-left: -161px;
      background-position: -329px center;
    }

    @media screen and (min-width: 1024px) and (max-width: 1024px) {
      margin-left: -113px;
      background-position: -206px center;
    }

    .title {
      position: absolute;
      //width: 400px;
      top: 84px;
      left: 176px;
      color: white;
      font-size: 28px;
      //font-weight: 400;
      z-index: 10;

      @media screen and (max-width: 1024px) and (max-width: 1024px) {
        left: 176px;
        width: fit-content;
      }

      @media screen and (min-width: 768px) and (max-width: 768px) {
        width: fit-content;
        left: 195px;
      }
      
      @media screen and (min-width: 820px) and (max-width: 912px) {
        width: fit-content;
        left: 219px;
      }

      @media screen and (min-width: 1280px) and (max-width: 1280px) {
        left: 60px;
      }

      @include mobile {
        width: fit-content;
        left: 20px;
        top: 32px;
        font-size: 20px;
      }
    }

    .matter-stream-img {
      //position: absolute;
      //right: 0;
      max-width:  100%;      
      margin-left: 85px;

      @include mobile {
        //width: 100%;   
        display: none;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        display: none;
      }

      @media screen and (min-width: 1280px) and (max-width: 1280px) {
        margin-left: 0px !important ;
      }
    }
  }

  // .swiper-slide {
  //   width: 85% !important;
  // }

  .matter-stream-content-blogs {
    margin-top: 80px;

    .MuiGrid-item {
      @include mobile {
        margin-bottom: 40px !important;
      }
    }

    .contain-img {
      object-fit: contain;
    }

    .download {
      color: var(--Text-646464, #646464);
      font-family: "Be Vietnam Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      text-decoration-line: underline;
    }

    .category-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
    }

    .category-sub-title {
      font-size: 28px;
      font-weight: 600;
      font-family: 'Be Vietnam Pro';
    }

    .more-posts {
      font-size: 20px;
      font-weight: 400;
      padding-top: 6px;

      @include mobile {
        margin-top: 16px;
        padding-top: 0px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .blog-posts {

      .awards { 
        padding: 8px;
        border: 1px solid #CECFCF; 
        border-radius: 4px;
        margin-bottom: 24px;
        height: 90px;
        display: flex;
        
        img {
          width: 104px;
        }

        @include mobile{
          margin-bottom: 0;
        }
      }

      .more-posts-blog-img {
        //{borderRadius: '6px', marginBottom: '20px', minHeight: '290px', width: '100%'}
        border-radius: 6px;
        margin-bottom: 24px;
        height: 290px;
        width: 100%;
        object-fit: cover;

        @include mobile {
          margin-bottom: 16px;
        }
      }                
        
      .date {
        color: rgba(21, 21, 21, 0.60);
        font-family: 'Be Vietnam Pro';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: inline;

        @include mobile {
          font-size: 10px;
          font-weight: 500;
        }
      }
    
      .dot {
        height: 4px;
        width: 4px;
        background-color: #888888;
        border-radius: 50%;
        display: inline-block;
        margin-bottom: 3px;

        @include mobile {
          margin-bottom: 2px;
        }
      }
    
      .blog-data-title {
        color: var(--Black, #1D1B1C);
        font-family: 'Be Vietnam Pro';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 4px;
    
        @include mobile {
          font-size: 12px;
          line-height: 1.2;
          margin: 0;
        }
      }
    
      .blog-description {      
        color: var(--text-282828, #282828);
        font-family: 'Be Vietnam Pro';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 120%;
  
        @media screen and (max-width: 600px) {
          font-size: 14px;
          margin-bottom: 16px;
        }
      }
    }

  }

  .matter-news-content { 
    margin-top: 100px;
    margin-bottom: 120px;

    @include mobile {
      margin-top: 68px;
    }      

    .MuiGrid-root .MuiGrid-item {
      width: 370px;
    }

    .filters {
      margin-top: 20px;    
  
      .sort-by-icon {
        background: none !important;
      }
  
      .caret-icon {
        margin-left: 180px;
      }
      
      .filter-by, .sort-by {      
        color: var(--text-646464, #646464);
        font-family: Be Vietnam Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 19.2px */
        margin-bottom: 12px;    
      }
      
      @media screen and (max-width: 500px) {
        margin-top: 0px;
        .filter-by, .sort-by {
          font-size: 14px;
        }      
        .caret-icon {
          margin-left: 135px;
        }
      }
  
      .filter-dropdown {
        display: flex;
        gap: 16px;      
      }
  
      .adornment {
        z-index: 9;
        display: flex;
        position: absolute;
        right: 0;
        width: 100%;
        height: 22px;
        justify-content: start;      
  
        p {
          color: #000;
          font-family: Be Vietnam Pro;
        }
      }
  
      .downCaretBlackIcon {
        margin: 16px;
      }
  
      .MuiGrid-item {
        padding-top: 0;
      }    
  
      .MuiInputAdornment-root {
        z-index: 0;
        background: white;
        height: auto;
        padding-left: 5px;
        position: absolute;
        //right: 0;
        cursor: pointer;
        pointer-events: none;
        padding-right: 8px;      
  
        .adornmentDate {
          margin-left: 8px;
          margin-right: 130px;
          color: #000;
          font-family: Be Vietnam Pro;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 16px */
  
          @media screen and (max-width: 500px) {
            margin-right: 90px;
            font-size: 14px;
          }
        }
  
        .adornmentReadTime {
          margin-left: 6px;
          margin-right: 90px;
          color: #000;
          font-family: Be Vietnam Pro;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%; /* 16px */
          @media screen and (max-width: 500px) {
            margin-right: 40px;
            font-size: 14px;
          }
        }
  
        .closeBtn {
          position: absolute;
          z-index: 20;
        }
      }
  
      .MuiInputBase-root {
        border-radius: 2px;
        border: 1px solid var(--Font-Color-3---Light-theme, #B3B3B3);
        width: 220px;
  
        @media screen and (max-width: 500px) {
          width: 169px;
        }
      }
  
      .MuiSelect-select {
        background: white;
        width: 160px;
        z-index: 9;
        //padding-right: 0px !important;
  
        @media screen and (max-width: 500px) {
          width: 105px;
        }
      }
  
      .MuiSelect-icon {
        display: none;
      }    
    }

    .filter-options {
      margin-top: 60px;
      margin-bottom: 48px;

      @media screen and (max-width: 899px) {
        .sort-by-section{
          margin-top: 24px;
          margin-left: 87px;
        }
      }            
    }

    .filter-options-error {
      margin-top: 40px;
      margin-bottom: 48px;

      @media screen and (max-width: 899px) {
        .sort-by-section{
          margin-top: 24px;
          margin-left: 87px;
        }
      }        
    }

    .recent-blogs {
      margin-bottom: 72px;
      @include mobile {
        margin-top: 40px;
      }
    }    

    .blog-section {
      display: flex;
      gap: 16px;
      border-bottom: 1px solid #CECFCF;
      margin-bottom: 32px;
      //margin-top: 68px;      

      .swiper {
        margin: 0;
      }

      .swiper-slide {
        width: fit-content !important;
      }
      
      @include mobile {
        margin-bottom: 40px;
        //width: fit-content;
      }
    }

    .news-title {
      margin-bottom: 60px;

      @include mobile {
        display: block;
        margin-bottom: 0px;
      }
    }

    // .matter-news {
    //   @include mobile {
    //     display: block;
    //   }
    // }

    .category-title {
      // display: flex;
      // justify-content: space-between;
      // margin-bottom: 20px
      font-size: 18px;
      line-height: normal;   
      
      @media screen and (max-width: 360px) {
        margin-left: 4px !important;
      }
      
      @include mobile {
        font-size: 12px;
      }

      img {
        @include mobile{
          width: 10px;
          height: 8px;
          margin-left: 8px !important;
        }
      }
    }

    .category-title-news {
      font-size: 24px;
      margin-left: 14px;

      @media screen and (max-width: 360px) {
        margin-left: 6px;
        font-size: 14px !important;
      }

      @include mobile {
        font-size: 16px;
      }
    }

    .totalSearch {
      margin-top: 56px;
      margin-bottom: 24px;
      font-family: "Be Vietnam Pro";
      font-size: 16px;
      font-weight: 400;

      @include mobile {
        margin-top: 0;
        font-size: 14px;
      }

      .searchValue {
        font-weight: 600;
      }
    }

    .ask-cover {
      // position: relative;
      //margin-bottom: 60px;
      width: 372px;
      height: 48px;
      max-width: 100%;
      
      // z-index: 9999;

      // @media screen and (max-width: 1200px) {
      //   //margin-top: 40px;
      //   width: 350px;
      // }

      @include mobile {
        //margin-top: 20px;
        width: 350px;
        height: 32px;
      }

      input {
        padding: 15px 16px;            
        border-radius: 4px;
        border: 1px solid var(--Text-CECFCF, #CECFCF);
        background: #FFF;
        
        font-size: 14px;
        width: 100%;
        font-family: 'Be Vietnam Pro';
        //border: none;
        outline: none;
        @include tab-xs {
          &::placeholder {
            font-size: 15px;
          }
          font-size: 15px;
        }      
        
        @include mobile {
          &::placeholder {
            font-size: 14px;
          }
          font-size: 14px;
        }
      }

      img {
        position: relative;
        cursor: pointer;
        left: 90%;
        top: -70%;

        @media screen and (max-width: 400px) {
          left: 93%;
        }

        @media screen and (min-width: 400px) and (max-width: 500px) {
          left: 101% !important;
        }

        @media screen and (min-width: 500px) and (max-width: 550px) {
          left: 138% !important;
        }

        // @include mobile {
        //   left: 93%;
        //   top: -70%;
        // }
      }

      .faq-search-list {
        position: absolute;
        width: 100%;
        top: 50px;
        border: 1px solid #c8c8c8;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
        background-color: white;
        overflow-y: auto;
        max-height: 260px;
        display: none;
        transition: .3s;

        &.show {
          display: block;
        }

        li {
          padding: 12px 20px;
          transition: .3s;
          cursor: pointer;

          &:last-child{
            padding-top: 15px;
            padding-bottom: 15px;
            position: sticky;
            bottom: 0;
            background: white;
          }
          &:not(:first-child) {
            border-top: 1px solid #dbdbdb;
          }
          &:not(:last-child) {
            &:hover {
              background: #f5f5f5;
            }
          }

          p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-width: 100%;
          }

          a {
            font-size: 16px;
            @media screen and (max-width: 768px){
              font-size: 14px;
            }
          }
        }
      }
    }

    .category-sub-title {
      font-weight: 400;
      padding: 8px 16px;
      // @media screen and (max-width: 500px) {
      //   width: 250px;
      // }
      @include mobile {
        font-size: 18px !important;
      }
    }

    .news-category {
      font-size: 14px !important;
      font-weight: 600;
      padding: 12px 24px;

      @include mobile {
        font-size: 12px !important;
        font-weight: 500;
        padding: 10px 12px;
      }
    }

    .boldName {
      color: #000 !important;
      font-weight: 600;
      border-bottom: 1px solid #000;
      //line-height: 120%;
    }
  
      .leader-card {
        border: 1px solid var(--Text-CECFCF, #CECFCF);
        border-radius: 6px;
        background: #fffffF;
        padding: 26px 47px 0px 46px;
                                           
              img {
                  width: 100%;
                  height: 100%;
              }
  
              // .MuiSvgIcon-root {
              //     position: absolute;
              //     top: 12px;
              //     right: 12px;
              //     font-size: 30px;
              //     width: 30px;
              //     height: 30px;
  
              //     @media screen and (max-width: 390px) {
              //         top: 8px;
              //         right: 8px;
              //         font-size: 25px;
              //         width: 25px;
              //         height: 25px;
              //     }
              // }
          
  
          // p {
          //     &.small {
          //         letter-spacing: .8px;
          //     }
          // }
          // p{
          //     &.ex-small{
          //         color: #6d6d6d;
          //     }
          // }
      }
    
  }
  

}
