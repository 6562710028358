@import '../../scss/mixins';

.blogs-page-wrapper {

  .MuiSnackbar-root{
    top: 90%;    

    @media screen and (max-width: 500px) {
      top: 80%;
    }
  }

  .MuiSnackbar-root .MuiPaper-root {
    color: var(--text-ffffff, #FFF);
    font-family: Be Vietnam Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    background-color: #000;
    border-radius: 10px;
  }

  header {  
    .header-content {
      box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.2);
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      right: auto;
          
      .logo-img {
        filter: invert(1);
      }
      
      .links-list {
        a {
          color: var(--white);
    
          &.active {
            color: var(--primary);
          }
        }
      }
    }
  }      

  .blog-carousel-content {
    position: absolute;
    top: calc(50% + 165px);
    left: calc((100% - 1170px) / 2);
    transform: translateY(-50%);
    z-index: 9;

    .blog-carousel-title {
      width: 540px;          
      overflow: hidden;
      font-size: 36px;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      // text-overflow: ellipsis;
    }

    .blog-carousel-intro {
      width: 440px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
    }

    @media screen and (max-width: 2950px) {
      top: calc(40%);
    }

    @media screen and (max-width: 2750px) {
      top: calc(40%);
    }
    
    @media screen and (max-width: 2350px) {
      top: calc(50%);
    }

    @media screen and (max-width: 2050px) {
      top: calc(50%);
    }

    @media screen and (max-width: 1850px) {
      top: calc(50% + 100px);
    }

    @media screen and (max-width: 1400px) {
      top: calc(50% + 165px);
      left: calc((100% - 1170px) / 2);
    }

    @media screen and (max-width: 1290px) {
      left: 60px;
    }

    @media screen and (max-width: 940px) {
      top: calc(50% + 100px);
    }
      
    @media screen and (max-width: 880px) {            
      top: calc(50% + 50px);
      
      .blog-carousel-title {              
        margin-bottom: 15px !important;
      }
            
      .blog-carousel-intro {
        margin-bottom: 24px !important;
      }            
    }
      
    @include tab-xs {
      left: 30px;
      top: calc(50% - 120px);
      transform: none;

      h2 {
        font-size: 30px !important;
        }
      p {
          font-size: 16px !important;
        }
    }

    @media screen and (max-width: 660px) {
      h2 {
          font-size: 24px !important;
          }
      p {
          font-size: 14px !important;
        }
    }
      
    @include mobile {
      left: 16px;
      max-width: calc(100% - 32px);
      top: calc(50% - 180px);
    }

    @media screen and (max-width: 500px) {
      top: calc(50%);
            
      .blog-carousel-title {              
        width: auto;
        font-size: 22px !important;
      }
      
      .blog-carousel-intro {
        width: auto;
        -webkit-line-clamp: 2;
      }
    }      
  }
    
  .carousel-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 750px;
    background: linear-gradient(90deg, #000 23.26%, rgba(0, 0, 0, 0.00) 95.23%);   
    padding-left: 350px;  
    
    @media screen and (max-width: 500px) {                      
      background: #0D0D0D;
      padding-left: 0px;   
      height: 574px;       
      padding-bottom: 180px;                  
    }    
        
    .carousel-image {
      position: relative;
      min-width: 100%;
      min-height: 100%;
      z-index:-1;
      display:block;
      transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
      object-fit: cover;
      
      &.for-mob {
        display: none;
      }

      @media screen and (max-width: 500px) {                      
        //height: 574px;
        //padding-bottom: 180px;
        z-index:1;
        &.for-mob {
          display: block;          
          mask-image: linear-gradient(0deg, rgba(5, 5, 5, 0.00) 24.5%, #050505 81.34%);
        }
    
        &.for-desktop {
          display: none;
        }                          
      }
    }        
  }

  .carousel-dots {
    position: absolute;
    top: calc(50% + 410px);
    left: calc((100% - 1175px) / 2);
    transform: translateY(-50%);
    z-index: 9;

    @media screen and (max-width: 2950px) {
      top: calc(60%);
      left: calc((100% - 1180px) / 2);
    }

    @media screen and (max-width: 2750px) {
      top: calc(60%);
      left: calc((100% - 1180px) / 2);
    }

    @media screen and (max-width: 2350px) {
      top: calc(50% + 250px);
      left: calc((100% - 1180px) / 2);
    }

    @media screen and (max-width: 2050px) {
      top: calc(50% + 250px);
      left: calc((100% - 1180px) / 2);
    }

    @media screen and (max-width: 1850px) {
      top: calc(50% + 350px);
      left: calc((100% - 1180px) / 2);
    }

    @media screen and (max-width: 1400px) {
      top: calc(50% + 410px);
      left: calc((100% - 1175px) / 2);
    }
    
    @media screen and (max-width: 1290px) {
      left: 55px;
    }

    @media screen and (max-width: 1010px) {
      top: calc(50% + 370px);
    }

    @media screen and (max-width: 940px) {
      top: calc(50% + 300px);
    }

    @media screen and (max-width: 880px) {
      top: calc(50% + 250px);
    }

    @include tab-xs {
      left: 25px;
      top: calc(50% + 160px);
      transform: none;
    }

    @media screen and (max-width: 660px) {
      top: calc(50% + 110px);
    }

    @include mobile {      
      left: 12px;
      max-width: calc(100% - 32px);      
      top: calc(50% + 50px);
    }
    
    @media screen and (max-width: 500px) {
      top: calc(50% + 260px);
    }
  }

  .carousel-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    width: 20px;
    height: 20px;
  }

  .carousel-dots-custom {
    height: 3px;
    width: 24px;
    background-color: #ffffff;  
    position: relative;
    opacity: 0.3;
    cursor: pointer;
  }

  .carousel-dots li.carousel-active .carousel-dots-custom {
    opacity: .8;
    color: #ffffff;
  }
 
  .full-blog {
    margin-top: calc(7%);
    margin-left: calc(23%);
    margin-right: calc(23%);

    @include tab-xs {
      margin-top: calc(13%);
      margin-left: calc(4%);
      margin-right: calc(4%);
    }

    .blog-date-share-heading {
      display: flex;
      justify-content: space-between;
    }

    .full-blog-main-image {
      border-radius: 6px;
      margin-bottom: 16px;
      width: 100%;
    }

    .blog-stream-data {
      .extra-pictures {
        margin-top: 35px;
      }

      p:empty {
        margin: 20px;
      }

      p {
          margin: 20px 0px;
          color: rgba(0, 0, 0, 0.70);
          font-family: Be Vietnam Pro;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 28.8px */                    
        
          strong {
            display: block;
            color: #000;
            font-family: Be Vietnam Pro;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 24px */
            text-transform: capitalize;
            margin-bottom: 24px;
            margin-top: 60px;
          }                
      }

      ul {
        margin-top: 24px;
        margin-bottom: 60px;
        color: rgba(0, 0, 0, 0.70);
        font-family: Be Vietnam Pro;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 28.8px */
        list-style-type: disc;
        padding-left: 20px;

        li {
          margin: 12px 0px;
        }
      }

      .btn-back {
        margin: 60px 0px 80px;
        padding: 15px 64px;
        background: #FFF;
        border: 1px solid #646464;
        border-radius: 4px;

        color: #646464;
        text-align: center;
        font-family: Be Vietnam Pro;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 28.8px */
        cursor: pointer;

        @media screen and (max-width: 500px) {
          padding: 8px 16px;
          font-size: 14px;
          margin: 60px 0px;
        }
      }
    }
  }

  .blog-data-content {
    padding: 120px 0px 150px;    

    @include mobile {
      padding: 64px 0px 100px;
    }

    .blog-section {
      display: flex;
      gap: 16px;
      border-bottom: 1px solid #CECFCF;
      margin-bottom: 32px;
      
      @media screen and (max-width: 500px) {
        margin-bottom: 40px;
      }
    }

    .category-title {
      // display: flex;
      // justify-content: space-between;
      // margin-bottom: 20px
      font-size: 18px;
      line-height: normal;        
      
      @include mobile {
        font-size: 12px;
      }

      img {
        @include mobile{
          width: 10px;
          height: 8px;
          margin-left: 8px !important;
        }
      }
    }

    .category-title-news {
      font-size: 24px;
      margin-left: 14px;

      @include mobile {
        font-size: 16px;
      }
    }

    .category-sub-title {
      font-weight: 400;
      padding: 8px 16px;
      @media screen and (max-width: 500px) {
        width: 250px;
      }
    }

    .boldName {
      color: #000 !important;
      font-weight: 600;
      border-bottom: 1px solid #000;
      //line-height: 120%;
    }

    .more-posts {
      color: var(--primary-color-primary, #036AFF);
      font-family: Be Vietnam Pro;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 28.8px */
      text-decoration-line: underline;
      cursor: pointer;

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }

    .filter-options {
      margin-top: 60px;
      margin-bottom: 48px;

      @media screen and (max-width: 899px) {
        .sort-by-section{
          margin-top: 24px;
          margin-left: 87px;
        }
      }            
    }

    .filter-options-error {
      margin-top: 40px;
      margin-bottom: 48px;

      @media screen and (max-width: 899px) {
        .sort-by-section{
          margin-top: 24px;
          margin-left: 87px;
        }
      }            
    }

    .blog-posts {
      .more-posts-blog-img {
        //{borderRadius: '6px', marginBottom: '20px', minHeight: '290px', width: '100%'}
        border-radius: 6px;
        margin-bottom: 24px;
        height: 290px;
        width: 100%;
        object-fit: cover;
      }
      @media screen and (max-width: 600px) {
        border-bottom: 1px solid #CFCFCF;
        margin-bottom: 12px;
      }      
    }

    .recent-blogs {
      margin-bottom: 72px;
      .filter-error-msg {
        color: var(--text-282828, #282828);
        font-family: Be Vietnam Pro;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 38.4px */
      }

      .recent-blogs-title {
        margin-top: 80px;
        margin-bottom: 24px;
      }

      @media screen and (max-width: 600px) {
        .filter-error-msg { 
          font-size: 18px;
        }
        .recent-blogs-title {
          margin-top: 50px;
          margin-bottom: 15px;
        }
      }

    }

    .blog-description {      
      color: var(--text-282828, #282828);
      font-family: Be Vietnam Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 120%;

      @media screen and (max-width: 600px) {
        font-size: 14px;
        margin-bottom: 16px;
      }
    }
  }

  .date {
    color: rgba(21, 21, 21, 0.60);
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: inline;
  }

  .dot {
    height: 4px;
    width: 4px;
    background-color: #888888;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 3px;
  }

  .blog-data-title {
    color: var(--Black, #1D1B1C);
    font-family: Be Vietnam Pro;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;    
    line-height: 120%;
    margin-bottom: 18px;

    @media screen and (max-width: 600px) {
      font-size: 22px;
      line-height: 1.2;
    }
  }

  .blog-introduction {    
    margin: 12px 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;

    color: var(--Black, #1D1B1C);
    font-family: Be Vietnam Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px;
  }
  
  .full-blog-intro {
    color: rgba(0, 0, 0, 0.70);
    font-family: Be Vietnam Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */  
    margin-bottom: 20px;
  }

  .hr {
    stroke-width: 0.5px;
    stroke: #BBB;
    margin: 18px 0px 30px;
  }

  .full-blog-image-desc {
    color: rgba(0, 0, 0, 0.50);
    font-family: Be Vietnam Pro;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 160%; /* 25.6px */
    margin-bottom: 60px;
  }  

  .filters {
    margin-top: 20px;    

    .sort-by-icon {
      background: none !important;
    }

    .caret-icon {
      margin-left: 180px;
    }
    
    .filter-by, .sort-by {      
      color: var(--text-646464, #646464);
      font-family: Be Vietnam Pro;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 19.2px */
      margin-bottom: 12px;    
    }
    
    @media screen and (max-width: 500px) {
      margin-top: 0px;
      .filter-by, .sort-by {
        font-size: 14px;
      }      
      .caret-icon {
        margin-left: 135px;
      }
    }

    .filter-dropdown {
      display: flex;
      gap: 16px;      
    }

    .adornment {
      z-index: 9;
      display: flex;
      position: absolute;
      right: 0;
      width: 100%;
      height: 22px;
      justify-content: start;      

      p {
        color: #000;
        font-family: Be Vietnam Pro;
      }
    }

    .downCaretBlackIcon {
      margin: 16px;
    }

    .MuiGrid-item {
      padding-top: 0;
    }    

    .MuiInputAdornment-root {
      z-index: 0;
      background: white;
      height: auto;
      padding-left: 5px;
      position: absolute;
      //right: 0;
      cursor: pointer;
      pointer-events: none;
      padding-right: 8px;      

      .adornmentDate {
        margin-left: 8px;
        margin-right: 130px;
        color: #000;
        font-family: Be Vietnam Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */

        @media screen and (max-width: 500px) {
          margin-right: 90px;
          font-size: 14px;
        }
      }

      .adornmentReadTime {
        margin-left: 6px;
        margin-right: 90px;
        color: #000;
        font-family: Be Vietnam Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        @media screen and (max-width: 500px) {
          margin-right: 40px;
          font-size: 14px;
        }
      }

      .closeBtn {
        position: absolute;
        z-index: 20;
      }
    }

    .MuiInputBase-root {
      border-radius: 2px;
      border: 1px solid var(--Font-Color-3---Light-theme, #B3B3B3);
      width: 220px;

      @media screen and (max-width: 500px) {
        width: 169px;
      }
    }

    .MuiSelect-select {
      background: white;
      width: 160px;
      z-index: 9;

      @media screen and (max-width: 500px) {
        width: 105px;
      }
    }

    .MuiSelect-icon {
      display: none;
    }    
  }
}

.MuiMenuItem-root.dropdown-options {
  color: var(--text-282828, #282828);
  font-family: Be Vietnam Pro !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}