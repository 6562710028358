@import './../../scss/mixins';

.ryi-module {
  header {
    height: 0;

    .header-content {
      box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.2);
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
    }
  }

  .banner-section {
    width: 100%;
    aspect-ratio: 7/3;

    @include tab-xs {
      aspect-ratio: 1/1;
    }

    @include mobile {
      aspect-ratio: 1/1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // margin-top: 75px;
      // margin-bottom: -40px;

      @media screen and (min-width:768px) {
        
        margin-top: 75px;
      }

      @media screen and (min-width:1024px) and (max-width:1440px){
        margin-bottom: -30px;
       
      }
      @media screen and (min-width:950px) and (max-width:1023px){
        margin-bottom: -20px;
       
      }
      @media screen and (min-width:900px) and (max-width:950px){
        margin-bottom: -15px;
       
      }
      @media screen and (min-width:820px) and (max-width:900px){
        margin-bottom: 10px;
       
      }
      // @media screen and (min-width:767px) and (max-width:800px){
      //   margin-bottom: 15px;
      //   margin-top: 75px;
      // }
      // @media screen and (min-width:1026px) and (max-width:1350px){
      //   margin-bottom: 20px;
      //   margin-top: 75px;
      // }
    }
  }

  .ryi-content {
    width: 1170px;
    // max-width: calc(100% - 120px);
    margin: -200px auto 100px;

    @include tab {
      margin: -150px auto 100px;
    }

    @media screen and (max-width: 577px){
      margin: 0px auto 0px;
    }

    // @include tab-xs {
    //   margin: -100px auto 80px;
    //   max-width: calc(100% - 60px);
    // }
    @media screen and (min-width:768px) and (max-width: 1200px) {
      max-width: calc(100% - 120px);
}
    @media screen and (min-width:576px) and (max-width: 768px) {
            margin: -100px auto 80px;
      max-width: calc(100% - 60px);
    }
    @media screen and (min-width:100px) and (max-width: 576px) {
   width: auto;
   max-width: auto;
    margin-top: -1px;
}

    // @include mobile {
    //   max-width: calc(100% - 32px);
    // }

    .enter-phone-card {
      width: 50%;
      background: var(--white);
      border: 1px solid #e4e4e4;
      box-shadow: 0px 4px 20px rgba(38, 62, 108, 0.06);
      border-radius: 10px;
      padding: 40px;
      position: relative;

      @include tab {
        width: 75%;
        padding: 30px;
      }

      @include tab-xs {
        width: 100%;
        padding: 20px;
      }

      @include tab-xs {
        padding: 16px;
      }

      &.fullWidth {
        width: 100%;
      }

      @include mobile{
        margin-top: -10px;
      }
    }

    .info-icon {
      width: 80px;
      height: 80px;
      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        width: 60px;
        height: 60px;
        margin-bottom: 30px;
      }

      @media screen and (max-width: 576px) {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
      }
    }
    .expect-text{
      color: #4d4d4d;
    }
    .expect-next{
      background: #F4F4F4;
      border-radius: 4px;
      padding: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include mobile{
        padding: 20px;
      }
      img{
        width: 38px;
        @include mobile{
          width: 30px;
        }
      }
      div{
        padding-left: 32px;
        width: calc(100% - 38px);
        @include mobile{
          padding-left: 24px;
          width: calc(100% - 24px);
        }
      }
    }
    .Mui-disabled{
      background: #f4f4f4;
      fieldset{
        border: none;
      }
    }
  }

  .cc-prop {
    p {
      position: relative;
      font-size: 14px;
      color: #a0a0a0;
      // color: #000000;

      &::after {
        position: absolute;
        content: '';
        right: -11px;
        top: 3.5px;
        height: 14px;
        width: 1px;
        background-color: #bcbcbc;
      }
    }
  }

  .cc-prop1 {
    p {
      position: relative;
      font-size: 14px;
      color:#000000de ;

      &::after {
        position: absolute;
        content: '';
        right: -11px;
        top: 3.5px;
        height: 14px;
        width: 1px;
        background-color: #bcbcbc;
      }
    }
  }
}

.resend-link {
  &.disable {
    opacity: 0.5;
    pointer-events: none;
  }
}

.MuiFormControl-root.mui-drop{
  margin-top: 0;
  margin-bottom: 0px;
  label{
    transform: translate(14px, 50%);
    color: var(--grey);
    font-weight: 300;

    &.Mui-focused{
      color: var(--black);
    }
  }

  .MuiSvgIcon-root{
    background: url(../../../public/assets/icons/down-caret-blue-icon.svg) no-repeat center;
    filter: grayscale(1) contrast(4);
    transform: scale(0.8);

    &.MuiSelect-iconOpen{
      transform: scale(0.8) rotate(180deg);
    }

    path{
      opacity: 0;
    }
  }
}

.Mui-focused{

  .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--black) !important;
  }
}

.MuiModal-root{
  .MuiMenu-paper{
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05) ;
    border: 1px solid #CECFCF;
    border-radius: 2px ;
  }
} 

.MuiMenuItem-root.interest-aera{
  font-family: 'Be Vietnam Pro', sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 10px;

  &:hover{
    font-weight: 600;
    background-color: transparent;
  }

  &.Mui-selected{
    background-color: transparent !important;
    font-weight: 600 !important;
  }

  span{
    display: none;
  }
}
.check-row{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  
  >div{
    @include mobile{
      width: 100%;
    }
    &:not(:last-child){
      margin-right: 104px;
      @include mobile{
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.otp-message{
  font-family: "Monument Extended";
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 28px */
letter-spacing: 0.2px;
width: 417px;
}