@font-face {
    font-family: 'Monument Extended';
    src: url('./MonumentExtended-Regular.otf');
}

@font-face {
    font-family: 'monument-bold';
    src: url('./MonumentExtendedBold.ttf');
}


@font-face {
    font-family: 'monument-ultraBold';
    src: url('./MonumentExtended-Ultrabold.otf');
}

.monument {
    font-family: 'Monument Extended', sans-serif !important;
    font-weight: 400;
}

.monument-bold {
    font-family: 'monument-bold', sans-serif;
}

.monument-ultraBold {
    font-family: 'monument-ultraBold', sans-serif;
}


@font-face {
    font-family: 'PP Monument Extended';
    src: url('./PPMonumentExtended-Regular.otf');
}
.pp-monument {
    font-family: 'PP Monument Extended', sans-serif !important;
    font-weight: 400;
}