@mixin scroller(
  $brodness: 6px,
  $bg: var(--grayDark),
  $bgcolor: var(--primary)
) {
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: $brodness;
    height: $brodness;
  }

  &::-webkit-scrollbar-track {
    width: $brodness;
    height: $brodness;
    background: $bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: $bgcolor;
    opacity: 0.5;
    width: $brodness;

    &:hover {
      opacity: 1;
    }
  }
}

// Media Queries
@mixin desktop-lg {
  @media screen and (max-width: 1560px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

@mixin desktop-sm {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin tab {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tab-sm {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tab-xs {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin mobile-xs {
  @media screen and (max-width: 360px) {
    @content;
  }
}

@mixin i5($i: 330) {
  @media (max-width: #{$i}px) {
    @content;
  }
}

@mixin gal($i: 360) {
  @media (max-width: #{$i}px) {
    @content;
  }
}

@mixin ip($i: 380) {
  @media (max-width: #{$i}px) {
    @content;
  }
}

@mixin ip-m($i: 381) {
  @media (min-width: #{$i}px) {
    @content;
  }
}

@mixin phonev($i: 480) {
  @media (max-width: #{$i}px) {
    @content;
  }
}

@mixin phonev-m($i: 481) {
  @media (min-width: #{$i}px) {
    @content;
  }
}

@mixin phone($i: 767) {
  @media (max-width: #{$i}px) {
    @content;
  }
}

@mixin phone-m($i: 768) {
  @media (min-width: #{$i}px) {
    @content;
  }
}

@mixin tabv($i: 992) {
  @media (max-width: #{$i}px) {
    @content;
  }
}

@mixin tabv-m($i: 993) {
  @media (min-width: #{$i}px) {
    @content;
  }
}

@mixin tab-m($i: 1201) {
  @media (min-width: #{$i}px) {
    @content;
  }
}

@mixin desk($i: 1300) {
  @media (max-width: #{$i}px) {
    @content;
  }
}

@mixin desk-m($i: 1301) {
  @media (min-width: #{$i}px) {
    @content;
  }
}

@mixin desk-l($i: 1400) {
  @media (max-width: #{$i}px) {
    @content;
  }
}

@mixin deskl-m {
  @media (min-width: 1500px) {
    @content;
  }
}

@mixin hlg {
  @media (min-height: 780px) {
    @content;
  }
}

@mixin lgc {
  @media (min-width: 1700px) and (min-height: 940px) {
    @content;
  }
}

// $icons: ('back-caret': 9px,
//   'next-caret': 9px,
//   'arrow-blue-sm': 8px,
//   'next-lg': 16px,
//   'prev-lg': 16px,
// );

$icons: (
  'back-caret': 9px,
  'next-caret': 9px,
  'arrow-blue-sm': 8px,
  'next-lg': 16px,
  'prev-lg': 16px,
  'plus': 16px,
  'down-caret-blue': 15px,
  'location': 20px,
  'info': 19px,
  'caret-right-blue': 14px,
  'check': 24px,
  'cross': 24px,
  'up-arrow': 19px,
  'arrow-down': 19px,
  'learnmore': 12px,
  'prebook-arrow': 10px,
  'scroll-down': 20px,
  'close': 16px,
  'next': 14px,
  'play-gray': 32px,
  'pause-gray': 32px,
  'charging': 40px,
  'gas': 40px,
  'uparrow': 14px,
);

@each $icon, $i in $icons {
  .#{$icon}-icon {
    width: $i;
    height: $i;
    display: inline-block;
    vertical-align: middle;
    background: url(../../../public/assets/icons/#{$icon}-icon.svg)
      no-repeat
      center;
  }
}

.scroll-down-icon {
  background: url('https://sitecdn.matter.in/newsite-uat/matter/assets/images/scroll-down-icon.webp')
    no-repeat center;
  background-size: contain;
}

.prebook-arrow-icon {
  background-size: contain;
}
