.legal-cover {
    background-color: var(--black);
    padding: 0px 60px;

    @media screen and (max-width: 768px) {
        padding: 0 30px;
    }

    @media screen and (max-width: 576px) {
        padding: 0 16px;
    }

    header {
        .header-content {
            background: var(--black);
            box-shadow: 1px 1px 1px 0px rgba(255, 255, 255, 0.2);

            .header-content-inner {
                &>a {
                    filter: invert(1);
                }

                a {
                    color: white;
                }

                .MuiButtonBase-root {
                    filter: invert(1);
                }
            }
        }
    }

    .explore-section {
        h3 {
            color: var(--white);
        }
    }
}

.TestRideTnCTitle{
    color: var(--Font-Color-2---Dark-Theme, #CECFCF);

/* Heading 9 - Subheading */
font-family: "Monument Extended";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 16.8px */
letter-spacing: 0.2px;
text-transform: uppercase;
}
.legal-content {
    color: var(--white);
    padding: 80px 0 50px;
    width: 1170px;
    margin: auto;
    max-width: 100%;
    @media screen and (max-width: 768px) {
        padding: 50px 0 30px;
    }
    @media screen and (max-width: 576px) {
        padding: 30px 0 20px;
    }
    li, p {
        line-height: 2;
        @media screen and (max-width: 450px){
            font-size: 12px;
        }
    }

    h2{
        @media screen and (max-width: 450px){
            font-size: 18px;
        }
    }

    p {
        margin-bottom: 20px;
    }

    .sec-wrapper {
        margin-bottom: 50px;
        @media screen and (max-width: 450px){
            margin-bottom: 30px;
        }

    }
    h5{
        line-height: 2;
    }
    ul {
        list-style-type: lower-roman;
        padding-left: 35px;
        @media screen and (max-width: 450px){
            padding-left: 25px;
        }
        &.lower-alpha {
            list-style-type: lower-alpha;
        }

        &.disc {
            list-style-type: disc;
        }

        li {
            margin-bottom: 20px;
        }
    }

   

    .explore-section {
        background: var(--black);

        .title {
            color: var(--white);
        }
    }
    ol {
        counter-reset: item;
        padding-left: 35px;
        @media screen and (max-width: 450px){
            padding-left: 25px;
        }
        & > li {
            display: block;
            position: relative;
            margin-bottom: 20px;
            &::before {
                content: counters(item, ".")".";
                counter-increment: item;
                position: absolute;
                margin-right: 100%;
                right: 10px;
            }
            &.monu-count{
                margin-bottom: 50px;
                @media screen and (max-width: 450px){
                    margin-bottom: 30px;
                }
                &::before{
                    font-family: 'Monument', sans-serif;
                    font-size: 20px;
                    @media screen and (max-width: 768px){
                        font-size: 18px;
                    }
                    @media screen and (max-width: 576px){
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .testridetnc ol {
        counter-reset: item;
        padding-left: 35px;
    
        @media screen and (max-width: 450px) {
            padding-left: 25px;
        }
    
        & > li {
            display: block;
            position: relative;
            margin-bottom: 20px;
    
            &::before {
                color: #FFF;
                font-family: "Monument Extended";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.2px;
                content: counters(item, ".") ".";
                counter-increment: item;
                position: absolute;
                margin-right: 100%;
                right: 10px;
            }
    
            &.monu-count {
                margin-bottom: 50px;
    
                @media screen and (max-width: 450px) {
                    margin-bottom: 30px;
                }
    
                &::before {
                    font-family: 'Monument Extended';
                    font-size: 20px;
                    color: #FFF;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0.2px;
    
                    @media screen and (max-width: 768px) {
                        font-size: 18px;
                    }
    
                    @media screen and (max-width: 576px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    

    .join-waitlist-order-list {
        list-style-type: upper-alpha;
        li::marker {
            font-size: 20px;
        }
    }

    .online-prebooking-order-list {
        list-style-type: upper-alpha;
        li::marker {
            font-size: 20px;
            font-weight: bold;
        }
    }


    

    .link {
        color: var(--white);
        text-decoration: none;
        transition: all .2s ease;

        &:hover {
            color: var(--primary);
            text-decoration: underline;
            transition: all .2s ease;
        }
    }
}