@import '../../scss//mixins';

#footerSec {
  position: relative;

  .title {
    text-align: center;
    margin: auto 16px 110px 16px;

    @media screen and (max-width: 699px) {
      margin-bottom: 30px;
    }
  }

  @include mobile{
    background: white !important ;
    padding-top: 20% !important;
  }

  .explore-section .content-container {
    @media screen and (max-width: 360px) {
      max-width: calc(100% - 32px);
    }
  }

  .explore-section {
    h3 {
      br {
        display: none;

        @include tab-sm {
          display: block;
        }
      }
    }

    .explore-container {
      position: absolute;
      left: 0;
      right: 0;
      .for-mobile{
        display: none;
      }

      @media screen and (max-width: 699px) {
        .for-desktop{
          display: none;
        }
        .for-mobile{
          display: block;
        }
      }

      .explore-card {
        background: var(--white);
        padding: 0.6px;
        border-radius: 0 6px 0 6px;
        clip-path: polygon(30px 0,
            100% 0,
            100% calc(100% - 30px),
            100% calc(100% - 30px),
            calc(100% - 30px) 100%,
            calc(100% - 30px) 100%,
            0 100%,
            0 30px);
        max-width: 1180px;
        margin: auto;
        height: 100%;

        @include tab-sm {
          border-radius: 0 3px 0 3px;
          clip-path: polygon(15px 0,
              100% 0,
              100% calc(100% - 15px),
              100% calc(100% - 15px),
              calc(100% - 15px) 100%,
              calc(100% - 15px) 100%,
              0 100%,
              0 15px);
        }

        .card-content {
          position: relative;
          max-width: 1180px;
          margin: 0 auto;
          padding: 40px 30px;
          height: 100%;
          z-index: 5;
          background: var(--black);
          border-radius: 0 6px 0 6px;
          clip-path: polygon(30px 0,
              100% 0,
              100% calc(100% - 30px),
              100% calc(100% - 30px),
              calc(100% - 30px) 100%,
              calc(100% - 30px) 100%,
              0 100%,
              0 30px);
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          

          @include desktop-sm {
            background-size: cover;
          }

          @media screen and (max-width: 1200px) {
            background: black;
          }

          @include tab-sm {
            padding: 25px 10px 30px 15px;
            border-radius: 0 3px 0 3px;
            clip-path: polygon(15px 0,
                100% 0,
                100% calc(100% - 15px),
                100% calc(100% - 15px),
                calc(100% - 15px) 100%,
                calc(100% - 15px) 100%,
                0 100%,
                0 15px);
          }

          @media screen and (max-width: 699px) {
            padding: 36px 24px;
            .card-content-detail{
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 0;
              .card-info{
                width: calc(100% - 44px);
                padding-left: 16px;
                @media screen and (max-width: 390px) {
                  width: calc(100% - 36px);
                  padding-left: 12px;
                }
              }
            }
          }
          @media screen and (max-width: 390px) {
            padding: 24px 16px;
          }

          .icon-cover {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;

            @include tab-sm {
              width: 50px;
              height: 50px;
            }
            @include tab-xs {
              width: 44px;
              height: 44px;
            }
            @media screen and (max-width: 390px) {
              width: 36px;
              height: 36px;
            }

            img {
              width: auto;
              height: auto;
            }
          }

          a {
            font-size: 14px;
            white-space: nowrap;
            @include mobile {
              font-size: 12px;
            }
            @media screen and (max-width: 420px) {
              font-size: 10px;
          }
          }

          h6 {
            @include mobile {
              font-size: 12px;
            }
          }

          p {
            @include mobile {
              font-size: 10px;
            }
          }
          ul{
            height: 66px;
            li{
              &:not(:first-child){
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }

  footer {
    background-color: var(--black);
    margin-top: 250px;
    padding: 380px 0 40px 0;
    color: white;

    @include desktop-sm {
      padding-left: 6px;
      padding-right: 6px;
    }

    @include tab-sm {
      margin-top: 207px;
      padding-top: 320px;
    }

    @media screen and (max-width: 699px) {
      margin-top: 350px;
      padding-top: 200px;
    }


    .footer-bottom {
      margin: auto;
    }

    .footer-wrapper {

      .MuiTypography-root.MuiTypography-inherit {
        color: white !important;
        font-weight: 300;
      }

      .say-hi-cover{
        margin-bottom: 3px;
        @media screen and (max-width: 1199px){
          margin-bottom: 24px;
        }
      }

      ul {
        li {
          &:not(:last-child) {
            padding-bottom: 6px;
          }

          a {
            font-size: 12px;
          }
        }
      }

      h6 {
        font-weight: 600;
      }

      .assisstance {
        margin-left: auto;
        text-align: right;
        font-weight: 400;

        @include tab-sm {
          text-align: left;
          margin-left: none;
        }

        a {
          text-decoration: none;

          @include tab-sm {
            text-decoration: underline;
          }
        }
      }

      .all-rights {
        @media screen and (max-width: 1199px){
          text-align: start;
        }
      }
      .all-links-cover{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        @media screen and (max-width: 1199px){
          justify-content: flex-start;
        }
        & > .white{
          margin-right: 72px;
          @media screen and (max-width: 576px){
            margin-right: 52px;
          }
          @media screen and (max-width: 390px){
            margin-right: 30px;
          }
        }
      }
    }

    .social-links{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: -10px;
      margin-right: -10px;
      @media screen and (max-width: 1199px){
        justify-content: flex-start;
        margin-top: 36px;
        img{
          width: 20px;
          height: 20px;
        }
      }
      a{
        border-radius: 50%;
      }
      .MuiIconButton-root{
        padding: 10px;
        &:hover{
          background: #424242;
        }
      }
    }
  }
}