.loader-cover {
  width: 100vw;
  background: rgba(0, 0, 0, .6);
  position: fixed;
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  lottie-player {
    height: 350px;
  }
}

// .fallback-loader{
//   .loader-cover{
//     background: red !important;
//   }
// }

.MuiFormControl-root,
.MuiFormControl-root .MuiTypography-root,
.MuiInputBase-input,
.otp-field__input {
  font-family: 'Be Vietnam Pro', sans-serif !important;
}

.MuiLink-root {
  cursor: pointer;
  text-decoration: inherit !important;
}

.MuiInputBase-root {
  border-radius: 2px !important;
  color: #18191a;

  .MuiInputBase-input {
    padding: 10.5px 14px;
    font-size: 14px;
    height: auto;
    @media screen and (min-width: 540px) and (max-width: 1500px) {
      font-size: 16px;
    }
    
  }

  fieldset {
    border-color: #cfd0d1;
  }
}

.container {
  max-width: 1300px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
  line-height: 0.6;
}

.custom-field {
  label {
    font-size: 12px;
    letter-spacing: .4px;
    line-height: 1;
    font-family: 'Monument', sans-serif;
    margin-bottom: 8px;
    display: inline-flex;
    align-items: center;
    color: var(--subText);
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 10px;
      letter-spacing: .3px;
      margin-bottom: 6px;
    }

    @media screen and (max-width: 576px) {
      font-size: 9px;
      letter-spacing: .2px;
      margin-bottom: 4px;
    }
  }

  &.black-bg {
    input {
      background: #1C1C1C;
    }

    .MuiInputBase-root {
      background: var(--black);
      textarea{
        padding: 0;
        height: auto !important;
      }
      .MuiInputAdornment-root {
        margin: 0;
      }

      .black {
        color: var(--white) !important;
      }
    }

    .MuiInputBase-input {
      color: var(--white);
      background: var(--black);

      &.Mui-disabled {
        opacity: .5;
      }
    }

    fieldset {
      border-color: var(--black);
    }

  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiLinearProgress-bar {
  background-color: var(--primary) !important;
}

.MuiTooltip-tooltip {
  background-color: var(--white) !important;
  color: var(--subText) !important;
  box-shadow: 0px 2px 24px 0px #00000029;
  padding: 12px 16px !important;
  line-height: 15px !important;

  @media screen and (max-width: 576px) {
    font-size: 11px !important;
  }

  .MuiTooltip-arrow {
    color: var(--white) !important;
  }
}

#black-theme-tooltip {
  .MuiTooltip-tooltip {
    background-color: var(--lightBlack) !important;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
    color: var(--white) !important;

    .MuiTooltip-arrow {
      color: var(--lightBlack) !important;
    }
  }
}

.MuiDialog-container {
  .MuiPaper-root {
    margin: 32px 16px;
    width: calc(100% - 32px);
    width: calc(100% - 32px);
  
  }
  .mui-root{
    margin: -25px 0px -46px -16px;
    color:#1D1D1D !important;
  }
}

.black-theme-dialog {
  .MuiPaper-root {
    background-color: var(--accountsCardBg);
  }
}

.black-theme-menu {
  .MuiMenu-paper {
    background: var(--lightBlack);

    .MuiMenuItem-root {
      color: var(--white);
    }
  }
}

.ReactCrop {
  min-height: 150px;
  width: 100%;

  &>div {
    &:first-child {
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: calc(100vh - 300px);
    }
  }

  .ReactCrop__image {
    margin: auto;
    max-height: calc(100vh - 300px);
  }
}

.reactEasyCrop_Container {
  max-height: calc(100vh - 300px);
  position: inherit !important;

  .reactEasyCrop_Image {
    max-height: calc(100vh - 300px);
    position: inherit !important;
  }
}

.MuiSnackbar-root {
  top: 50px;
  bottom: auto !important;
  left: 50% !important;
  right: auto !important;
  transform: translateX(-50%);
  width: max-content;
  max-width: 750px;
  @media screen and (max-width: 768px){
    max-width: calc(100% - 60px);
  }
  @media screen and (max-width: 576px){
    max-width: calc(100% - 32px);
  }

  .MuiPaper-root {
    padding: 6px 48px;
    border-radius: 0px;
    font-family: 'Monument', sans-serif;
    text-align: center;
    justify-content: center;

    @media screen and (max-width:992px) {
      padding: 4px 24px;
      font-size: 14px;
    }

    @media screen and (max-width:768px) {
      padding: 3px 20px;
      font-size: 13px;
    }

    @media screen and (max-width:576px) {
      padding: 2px 16px;
      font-size: 12px;
    }
  }

  &.success {
    .MuiPaper-root {
      background: var(--success);
    }
  }

  &.danger {
    .MuiPaper-root {
      background: var(--danger);
    }
  }
}

.MuiMenu-list {
  max-height: 300px;
}

.custom-square-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;

  @media screen and (max-width:576px) {
    width: 34px;
    height: 17px;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #434343;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      top: 4px;
      left: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border: 1px solid #5f5f5f;

      @media screen and (max-width:576px) {
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
      }
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked+.slider {
      background-color: var(--primary);
    }

    &:checked+.slider:before {
      -webkit-transform: translateX(19px);
      -ms-transform: translateX(19px);
      transform: translateX(19px);
      border-color: var(--white);

      @media screen and (max-width:576px) {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
      }
    }
  }
}


.MuiCheckbox-root {
  min-width: 38px;
  margin-top: -9px !important;
  margin-left: -9px !important;
  padding: 9px !important;
  border-radius: 50% !important;
}

@media screen and (max-width: 576px) {
  .MuiCheckbox-root {
    margin-top: -11px !important;
  }

  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }
}

.custom-slider {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 5px;
      bottom: 4px;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  input:checked+.slider {
    background-color: #2196F3;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
}

.rupee-symbol {
  font-weight: 500;
  transform: scaleX(1.65);
  display: inline-block;
}

.sucess-class{
  color: var(--Action-Success, #28C879) ;
  font-family: "Be Vietnam Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.not-found{
  color: var(--Action-Warning, #FE9820) ;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: flex-start;
}

.invalid-class{
  // color: var(--Action-Success, #28C879) ;
  font-family: "Be Vietnam Pro";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  display: flex;
  text-transform: lowercase;
}

.asterik-info {
  padding-left: 20px;

  li {
    padding: 19px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #dfdfdf;
    }
  }
}

.resend-link {
  &.disable {
    opacity: 0.5;
    pointer-events: none;
  }
}

.close-btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}
.MuiDialog-root{
  z-index: 9999 !important;
}
.req-assis{
  font-size: 14px;
  @media screen and (max-width: 576px){
    font-size: 12px;
  }
}