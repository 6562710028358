@import '../../scss//mixins';

header {
  height: 74px;
  transition: all 200ms ease;

  @include tab-xs {
    height: 62px;
  }

  @include mobile {
    height: 52px;
  }

  .header-content {
    position: fixed;
    z-index: 1100;
    width: 100vw;
    //top: 38px;
    left: auto;
    right: 0;
    background-color: var(--white);
    padding: 24px 60px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    @include tab-xs {
      padding: 18px 30px;
      height: 62px;

      &.normalHeight {
        padding: 18px 30px;
      }
    }

    @include mobile {
      padding: 15px 16px;
      height: 52px;

      &.normalHeight {
        padding: 11px 16px;
      }
    }

    // @media screen and (max-width: 450px) {
    //   top: 30px;
    // }

    @media screen and (max-width: 360px) {
      padding: 17px 16px;

      &.normalHeight {
        padding: 11px 16px;
      }
    }

    &.for-product-page {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &.for-hyperlocal-page {
      padding-top: 25px;
      padding-bottom: 30px;
    }
    .header-content-inner {
      max-width: 1600px;
      margin: auto;

      @include tab-xs {
        align-items: center;
      }

      a {
        img {
          display: table-cell;

          @include mobile {
            width: 96px;
          }

          @media screen and (max-width: 360px) {
            width: 80px;
          }
        }

        .profile-img-cover {
          display: none;
          width: 30px;
          height: 30px;
          border-radius: 6px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        @include mobile {
          .username {
            display: none;
          }

          .profile-img-cover {
            display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
          }
        }
      }
    }
  }

  .sidemenu {
    position: fixed;
    top: 48px;
    left: auto;
    right: 82px;
    background: #fff;
    border-radius: 5px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    height: auto;
    // max-height: 100%;
    min-width: 700px;
    min-height: 247px;
    padding: 30px 36px 24px;
    transform: translateY(10%);
    opacity: 0;
    visibility: hidden;
    // transition: all 180ms ease;
    z-index: -2;    
    filter: drop-shadow(-4px 4px 34px rgba(0, 0, 0, 0.17));    
  }
  .sidemenu .tiled-box {
    position: absolute;
    top: -5px;
    right: -3px;
    width: 4px;
    height: 8px;
    background: #fff;
    transform: rotate(45deg);
  }
  .for-product-page.sidemenu {
    box-shadow: 20px -20px 20px rgba(0, 0, 0, 0.05);
    top: 76px;
  }
  .for-legal-page.sidemenu {
    top: 86px;
  }
  .sidemenu.active {
    transform: translate(0px);
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }
  .nav-ham-icon {
    position: relative;
    cursor: pointer;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
    display: flex;
    z-index: 9999;
  }
  // .nav-ham-icon.open {
  //   background: #fff;
  // }
  .nav-ham-icon .ham-img {
    width: 24px;
    height: 24px;
  }
  .nav-ham-icon .ham-img.black {
    filter: invert(50%);
  }
  // .for-product-page.nav-ham-icon img {   
  //   filter: brightness(0.5) saturate(0%);
  // }
  .sidemenu h5 {display: inline-block;
    margin-bottom: 25px;
    cursor: pointer;
    opacity: .7;
    border-bottom: 1px solid #fff;
    transition: margin-left 1s;
  }
  .sidemenu li.li-support h5{
    //margin-bottom: 0rem;
  }
  .sidemenu #footer-text{
    margin-top: 2.5rem;
  }
  .sidemenu #footer-text .hr{
    display: none;
  }
  .sidemenu h5:hover {
    margin-left: 1rem;
    opacity: 1;
    border-bottom: 1px solid #000;
  }
  .sidemenu .footer-area {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .sidemenu .footer-area .top{
    display: flex;
    justify-content: start;
    font-size: 1rem;
  }
  .sidemenu .footer-area .top li{
    border-right: 1px solid #BABABA;
    padding: 0rem 0.5rem;    
  }
  .sidemenu .footer-area .top li:first-child{
    border-right: 1px solid #BABABA;
    padding-left: 0rem;    
  }
  .sidemenu .footer-area .top li:last-child {
    border-right: 0;
    padding-right: 0rem;
  }
  .sidemenu .footer-area a {
    color: #646464 !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .sidemenu .footer-area p{
    color: #000000;
    font-size: 10px;
    text-align: right;
    margin-top: 0.5rem;
  }
  .ic-ham-close {
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 2732px){    
    .links-list {
      position: relative;
    }
    .sidemenu {
      position: absolute;
      top: 23px;
      right: 23px;      
    }
    .for-legal-page.sidemenu, .for-product-page.sidemenu {
      top: 23px;
    }
  }

  @media screen and (max-width: 2049px){    
    .links-list {
      position: relative;
    }
    .sidemenu {
      position: absolute;
      top: 23px;
      right: 23px;      
    }
    .for-legal-page.sidemenu, .for-product-page.sidemenu {
      top: 23px;
    }
  }

  @media screen and (max-width: 1822px){
    .sidemenu .tiled-box {
      top: -4px;
      right: -2px;
    }
    .links-list {
      position: relative;
    }
    .sidemenu {
      position: absolute;
      top: 23px;
      right: 23px;      
    }
    .for-legal-page.sidemenu, .for-product-page.sidemenu {
      top: 23px;
    }
  }

  @media screen and (max-width: 1720px){    
    .sidemenu {
      position: fixed;
      top: 48px;
      right: 82px;      
    }
    .for-legal-page.sidemenu {
      top: 86px;
    }
    .for-product-page.sidemenu {
      top: 76px;
    }
  }

  @media screen and (max-width: 1023px) {
    .sidemenu .tiled-box {
      top: -4px;
      width: 3px;
      right: -1px;
    }
    .sidemenu {
      min-width: auto;
      left: 15px;
      right: 74px;
      top: 44px;
    }
    .for-product-page.sidemenu {
      top: 74px;
    }
    .for-legal-page.sidemenu {
      top: 83px;
    }
    .ic-ham-close{
      display: inline-block;
      width: 16px;
      height: 16px;
    }
    .sidemenu #footer-text{
      margin-top:0;
    }
    .nav-ham-icon .ham-img {
      width: 16px;
      height: 16px;
    }
    .sidemenu .footer-area .top{
      flex-direction: column;
    }
    .sidemenu .footer-area a{
      font-size: 14px;
      font-weight: 400;
    }
    .sidemenu .footer-area .top li {
      border: 0;
      padding: 0.5rem 0;
    }
    .sidemenu .footer-area p {
      margin-top: 1.2rem;
      text-align: left;
    }
    .hr {
      margin: 2rem 0rem;
      background: #000;
      opacity: 0.2;
      display: block !important;
    }
    // .nav-ham-icon{
    //   display: none;
    // }
  }
  @media screen and (max-width: 768px){
    .sidemenu .tiled-box {
      top: -4px;
      width: 3px;
      right: -1px;
    }
    .sidemenu {
      left: 15px;
      right: 44px;
      top: 38px;
      padding: 50px 36px 24px;
    }
    .for-legal-page.sidemenu {
      top: 77px;
    }
  }
  @media screen and (max-width: 576px){
    .sidemenu .tiled-box {
      right: -1px;
      top: -4px;
      width: 3px;
    }
    .sidemenu {
      left: 15px;
      right: 30px;
      top: 33px;
      padding: 45px 36px 24px;
    }
    .for-legal-page.sidemenu, .for-product-page.sidemenu {
      top: 72px;
    }
  }

  @media screen and (max-width: 450px){    
    .sidemenu .tiled-box {
      right: -1px;
      top: -4px;
      width: 3px;
    }
    .sidemenu {    
      left: 15px;
      right: 30px;
      top: 33px;  
      padding: 32px 36px 16px;
    }
    .sidemenu h5 {
      opacity: 1;
    }
    .for-legal-page.sidemenu, .for-product-page.sidemenu {
      top: 63px;
    }
  }  
  
  .menu-icon {
    width: 40px;
    height: 40px;
  }

  .links-list {
    align-items: center;
    
    a {
      letter-spacing: 1.2px;
      color: var(--black);

      &:not(:last-child) {
        margin-right: 40px;

        @include tab-xs {
          margin-right: 22px;
        }

        @include mobile {
          margin-right: 14px;
        }

        @media screen and (max-width: 360px) {
          margin-right: 10px;
        }
      }

      &.active {
        font-weight: 700;
        color: var(--primary);
      }

      &.d-none {
        display: none;
      }

      @include mobile {
        font-size: 10px;
      }

      @media screen and (max-width: 360px) {
        font-size: 9px;
      }

      &.monument {
        @media screen and (max-width: 360px) {
          max-width: 90px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

    }
  }
}
.ppf{
  display: flex;
  justify-content: center;
  align-items: center;
width: 20px;
height: 20px;
padding: 4px 2px;
justify-content: center;
align-items: center;
border-radius: 30px;
border: 0.5px solid rgba(255, 255, 255, 0.20);
background: rgba(255, 255, 255, 0.20);

color: rgba(255, 255, 255, 0.70);

text-align: center;
font-family: "Be Vietnam Pro";
font-size: 8px;
font-style: normal;
font-weight: 400;
}
.menu-drawer {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .MuiDrawer-paper {
    top: 80px;
    right: 75px;
    border-radius: 0 6px 0 6px;
    left: auto;
    height: auto;
    width: 355px;
    overflow: hidden;
    background-color: transparent;
    box-shadow: none;
    transition: transform 225ms cubic-bezier(800, 0, 0.8, 1) 0ms !important;
  }

  .close-menu-icon {
    width: 40px;
    height: 40px;
    position: fixed;
    top: 20px;
    right: 60px;
  }

  nav {
    padding: 45px;
    position: relative;
    clip-path: polygon(20px 0,
        100% 0,
        100% calc(100% - 20px),
        100% calc(100% - 20px),
        calc(100% - 20px) 100%,
        calc(100% - 20px) 100%,
        0 100%,
        0 20px);
    z-index: 1;
    background: var(--black);
    border-radius: 0 6px 0 6px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom right, #fff, #1f7afe);
      padding: 1px;
      border-radius: 0 6px 0 6px;
      z-index: -2;
      clip-path: polygon(20px 0,
          100% 0,
          100% calc(100% - 20px),
          100% calc(100% - 20px),
          calc(100% - 20px) 100%,
          calc(100% - 20px) 100%,
          0 100%,
          0 20px);
    }

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      border-radius: 0 6px 0 6px;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: var(--black);
      z-index: -1;
      clip-path: polygon(20px 0,
          100% 0,
          100% calc(100% - 20px),
          100% calc(100% - 20px),
          calc(100% - 20px) 100%,
          calc(100% - 20px) 100%,
          0 100%,
          0 20px);
    }
  }

  a {
    letter-spacing: 1.2px;
    color: var(--white);
    width: 100%;
    padding: 7px 0;
    display: block;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      color: var(--primary);
    }

    &.active {
      font-weight: 700;
      color: var(--primary);
    }
  }
}